import { Observable, catchError, map, of } from 'rxjs'
import { ajax } from 'rxjs/ajax'
import { AiHeaderUtils } from '../utils/headers'
import { AiRoutes } from '../constants/routes'
import { AiModel } from '../../../models/ai/model'

const { Create } = AiRoutes.Translate

// ####################################################################################################
// ~~~~~~ Translate: From string in some lang to string in other lang
// ####################################################################################################

type Res = DN.Models.Ai.Translation.Api.Create.Res
type ResParsed = DNApiResParsed<DN.Models.Ai.Translation.Api.Create.ResParsed>
type ResError = DNApiError<Infos[]>

export const apiAiTranslateCreate$ = (
  sentences: DN.Models.Ai.Sentence[],
  lang?: string,
  headers?: AppHeaders,
) => {
  //

  const body: DN.Models.Ai.Translation.Api.Create.Req = sentences.map((sentence) => ({
    text: sentence.text.trim(),
  }))

  return ajax<Res>({
    ...AiHeaderUtils.genTranslateHeaders(headers),
    method: Create.Method,
    url: Create.getUrl(lang),
    body,
  }).pipe(
    map((res): ResParsed => {
      return {
        status: res.status,
        response: AiModel.Translation.createParseRes(sentences, res.response),
      }
    }),

    catchError((res: ResError): Observable<ResError> => {
      return of({
        status: res.status,
        response: [{ id: 'wildcard', values: { value: `ERR IA Translate: ${res.response}` } }],
      })
    }),
  )
}
