// https://gist.github.com/lopspower/03fb1cc0ac9f32ef38f4
export const HexAlpha = {
  100: 'FF',
  90: 'E6',
  85: 'D9',
  80: 'CC',
  70: 'B3',
  60: '99',
  50: '80',
  40: '66',
  30: '4D',
  20: '33',
  24: '3D',
  15: '26',
  10: '1A',
  8: '14',
  0: '00',
} as const
