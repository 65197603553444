import { Trans } from '../../../../../../../../../components/common/intl/trans'
import { LazySVG } from '../../../../../../../../../components/svgs/lazy-svg/component'
import { useJoinedBehaviourLogicContentMainToolbarsMainButtonsAi } from '../../../../../../../behaviour-logic/content/main/toolbars/main/buttons/ai/hook'
import { ClassName, STJoinedDesktopContentMainToolbarsMainButtonsAi } from './style'

// ~~~~~~ Constants

const AiIcon = LazySVG('icons/ai')

// ~~~~~~ Types

type Props = {
  video: HTMLVideoElement | null
}

// ~~~~~~ Component

export const JoinedDesktopContentMainToolbarsMainButtonsAi: React.FC<Props> = ({ video }) => {
  // ~~~~~~ Hooks

  const logic = useJoinedBehaviourLogicContentMainToolbarsMainButtonsAi(video)

  // ~~~~~~ Render

  return (
    <STJoinedDesktopContentMainToolbarsMainButtonsAi
      className="with-tooltip"
      onClick={logic.onClickIa}
    >
      {/* Icon */}

      <AiIcon className={`${ClassName}--icon ${logic.isRunning ? 'running' : ''}`} size={24} />

      {/* Tooltip */}

      <span
        ref={logic.tooltipRef}
        className="tooltiptext"
        style={{
          top: logic.tooltipTop,
        }}
      >
        <Trans id="wildcard" values={{ value: logic.isRunning ? 'Stop AI' : 'AI Assistence' }} />
      </span>

      {/* -- */}
    </STJoinedDesktopContentMainToolbarsMainButtonsAi>
  )
}
