import { BcastConnectEvents } from '@dn/bcast/dist/types/typing/partial-service'
import { StateObservable, ofType } from 'redux-observable'
import { Observable, filter, map } from 'rxjs'
import { StoreState } from '../../../../../models/app/model'
import { Config } from '../../../../../config'
import { BroadcastAC } from '../../../../actions/bcast/actions'
import { ConstBcast } from '../../../../../constants/bcast'

type Action = Extract<BcastConnectEvents, { type: '@dn/Bcast-participant-disconnected' }>

export const chainingEventsFromBcasterDisconnectedToExit$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType('@dn/Bcast-participant-disconnected'),

    filter(() => Config.Features.BcastService2 && !state$.value.dnBcast.iAmBcaster),

    filter(({ payload }) => {
      const participant = payload.participant

      if (!('metadata' in participant)) return false

      let kind = ''

      try {
        const parsed = JSON.parse(participant.metadata || '{}')

        kind = 'kind' in parsed ? parsed.kind : ''

        //
      } catch (err) {
        return false
      }

      return kind === ConstBcast.Participant.Bcaster
    }),

    map(() => BroadcastAC.exit('session-end')),
  )
