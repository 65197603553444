// ~~~~~~ IA

import { UtilsStore } from '@dn/utils'

const initModel: DN.Models.Ai.Config.Model = {
  uiStatus: 'init',

  description: false,

  ocr: 'disabled',

  tts: false,
}

const prefix = 'mt-ai-model'

// ~~~~~~ Fields Mutators/Reducers

export const AiFieldsMR = {
  __model__: UtilsStore.genModelMR(prefix, initModel),
}

// ~~~~~~ Description

const initDescriptionModel: DN.Models.Ai.Description.Model = {
  captions: [],
  tags: [],
} as const

// ~~~~~~ OCR

const initOcrModel: DN.Models.Ai.Ocr.Model = [] as const

const ocrCreateParseRes = (
  data: DN.Models.Ai.Ocr.Api.Create.Res,
): DN.Models.Ai.Ocr.Api.Create.ResParsed => {
  //

  return {
    language: data.language,

    modelVersion: data.modelVersion,

    textAngle: data.textAngle,

    lines: data.regions
      .flatMap((region) => region.lines)
      .map((line) => ({
        id: crypto.randomUUID(),
        boundingBox: line.boundingBox,
        text: line.words.map((word) => word.text.trim()).join(' '),
      })),
  }
}

// ~~~~~~ TTS

const initTtsModel: DN.Models.Ai.Tts.Model = [] as const

// ~~~~~~ Translation

const initTraslationModel: DN.Models.Ai.Translation.Model = [] as const

const translationCreateParseRes = (
  sentences: DN.Models.Ai.Sentence[],
  data: DN.Models.Ai.Translation.Api.Create.Res,
): DN.Models.Ai.Translation.Api.Create.ResParsed => {
  //

  return data.map((line, idx) => ({
    fromLang: line.detectedLanguage.language,

    toLang: line.translations[0].to,

    translation: {
      id: sentences[idx]?.id,
      text: sentences[idx]?.text,
      boundingBox: sentences[idx]?.boundingBox,
      trans: line.translations[0].text,
    },
  }))
}

// ~~~~~~

export const AiModel = {
  initModel,

  Description: {
    initModel: initDescriptionModel,
  },

  Ocr: {
    initModel: initOcrModel,
    createParseRes: ocrCreateParseRes,
  },

  Tts: {
    initModel: initTtsModel,
  },

  Translation: {
    initModel: initTraslationModel,
    createParseRes: translationCreateParseRes,
  },
} as const
