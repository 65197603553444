import { Action } from 'redux'

import { rootReducer } from './bcast/root/reducer'
import { intlReducer } from './bcast/intl/reducer'
import { modalReducer } from './bcast/modals/reducer'
import { debuggerReducer } from './bcast/debugger/reducer'
import { streamManagerServerSubMainReducer } from './stream-manager/server/sub-main/reducer'
import { streamManagerServerPubMainReducer } from './stream-manager/server/pub-main/reducer'
import { streamManagerSubMainReducer } from './stream-manager/sub-main/reducer'
import { exitReducer } from './bcast/exit/reducer'
import { statsReducer } from './bcast/stats/reducer'
import { sharedMainStreamReducer } from './bcast/shared-main-stream/reducer'
import { currentUserReducer } from './bcast/current-user/reducer'
import { streamManagerPubMainReducer } from './stream-manager/pub-main/reducer'
import { timeCheckReducer } from './bcast/time-check/reducer'
import { bcastDurationReducer } from './bcast/bcast-duration/reducer'
import { paintBcastStreamPicReducer } from './bcast/paint-bcast-stream/pic/reducer'
import { paintBcastStreamDrawReducer } from './bcast/paint-bcast-stream/draw/reducer'
import { paintBcastStreamVideoReducer } from './bcast/paint-bcast-stream/video/reducer'
import { StoreStateProps } from '../../models/app/model'
import { storePlanReducer } from './store/plan/reducer'
import { storeProductsReducer } from './store/products/reducer'
import { subscriptionReducer } from './bcast/subscription/reducer'
import { providerGoogleReducer } from './bcast/providers/google/reducer'
import { googleClassRoomMetaListReducer } from './bcast/google-class-room/list/reducer'
import { googleClassRoomAnnouncementBcastLinkReducer } from './bcast/google-class-room/announcement-bcast-link/reducer'
import { imageToShareReducer } from './bcast/image-to-share/reducer'
import { googleClassRoomAnnouncementCaptureReducer } from './bcast/google-class-room/announcement-capture/reducer'
import { googleSlidesPresentationMetaListReducer } from './bcast/google-slides/list-presentations/reducer'
import { googleSlidesCreatePresentationReducer } from './bcast/google-slides/create-presentation/reducer'
import { googleSlidesAddImageToSlideReducer } from './bcast/google-slides/add-image-to-slide/reducer'
import { screenShotReducer } from './bcast/screenshot/reducer'
import { uiSettingsReducer } from './bcast/ui/settings/reducer'
import { commChannelReducer } from './bcast/comm-channel/reducer'
import { livePaintDrawReducer } from './bcast/live-paint/draw/reducer'
import { livePaintReducer } from './bcast/live-paint/reducer'
import { devicesReducer } from './bcast/devices/reducer'
import { streamManagerPubCamMicReducer } from './stream-manager/pub-cam-mic/reducer'
import { streamManagerSubCamMicReducer } from './stream-manager/sub-cam-mic/reducer'
import { streamManagerServerPubCamMicReducer } from './stream-manager/server/pub-cam-mic/reducer'
import { streamManagerServerSubCamMicReducer } from './stream-manager/server/sub-cam-mic/reducer'
import { sharedCamMicStreamReducer } from './bcast/shared-cam-mic-stream/reducer'
import { stunTurnCredentialsReducer } from './bcast/stun-turn-credentials/reducer'
import { LivePaintVideoReducer } from './bcast/live-paint/video/reducer'
import { NetworkCongestionPubReducer } from './bcast/network-congestion/pub/reducer'
import { NetworkCongestionSubReducer } from './bcast/network-congestion/sub/reducer'
import { viewersReducer } from './bcast/viewers/reducer'
import { uiLayoutPanelsReducer } from './bcast/ui/layout-panels/reducer'
import { bcastCountdownReducer } from './bcast/bcast-countdown/reducer'
import { userSettingsReducer } from './bcast/user-settings/show/reducer'
import { userSettingsEditReducer } from './bcast/user-settings/edit/reducer'
import { paintBcastStreamReducer } from './bcast/paint-bcast-stream/reducer'
import { uiLayoutViewerMobileReducer } from './bcast/ui/layout-viewer-mobile/reducer'
import { uiMobileToolbarReducer } from './bcast/ui/mobile-toolbar/reducer'
import { uiPipFullscreenReducer } from './bcast/ui/pip-fullscreen/reducer'
import { uiOnboardingReducer } from './bcast/ui/onboardidng/reducer'
import { uiExtensionMenusReducer } from './bcast/ui/extension-menus/reducer'
import { feedbackEndBcastReducer } from './bcast/feedback/end-bcast/reducer'
import { uiPipReducer } from './bcast/ui/pip/reducer'
import { themeReducer } from './bcast/theme/reducer'
import { streamContextReducer } from './bcast/stream-context/reducer'
import { storeDurationReducer } from './bcast/store-duration/reducer'
import { dnBcastReducer } from './dn-bcast/reducer'
import { aiConfigReducer } from './ai/config/reducer'
import { aiDescriptionReducer } from './ai/description/reducer'
import { aiOcrReducer } from './ai/ocr/reducer'
import { aiTranslationReducer } from './ai/translation/reducer'
import { aiTtsReducer } from './ai/tts/reducer'

type Reducers = {
  [key in StoreStateProps]: (state: any, action: Action) => any
}

const reducers: Reducers = {
  // Libs

  dnBcast: dnBcastReducer,

  // Bcast

  root: rootReducer,
  debugger: debuggerReducer,
  intl: intlReducer,
  modals: modalReducer,
  theme: themeReducer,

  // User Interface

  uiLayoutPanels: uiLayoutPanelsReducer,
  uiLayoutViewerMobile: uiLayoutViewerMobileReducer,
  uiMobileToolbar: uiMobileToolbarReducer,
  uiSettings: uiSettingsReducer,
  uiPipFullscreen: uiPipFullscreenReducer,
  uiPip: uiPipReducer,
  uiOnboarding: uiOnboardingReducer,
  uiExtensionMenus: uiExtensionMenusReducer,

  // Devices

  devices: devicesReducer,

  // Current User

  currentUser: currentUserReducer,
  userSettings: userSettingsReducer,
  userSettingsEdit: userSettingsEditReducer,
  subscription: subscriptionReducer,

  // Google

  providerGoogle: providerGoogleReducer,

  googleClassRoomMetaList: googleClassRoomMetaListReducer,
  googleClassRoomAnnouncementBcastLink: googleClassRoomAnnouncementBcastLinkReducer,
  googleClassRoomAnnouncementCapture: googleClassRoomAnnouncementCaptureReducer,

  googleSlidesPresentationMetaList: googleSlidesPresentationMetaListReducer,
  googleSlidesCreatePresentation: googleSlidesCreatePresentationReducer,
  googleSlidesAddImageToSlide: googleSlidesAddImageToSlideReducer,

  sharedMainStream: sharedMainStreamReducer,
  sharedCamMicStream: sharedCamMicStreamReducer,

  exit: exitReducer,

  // Stats

  stats: statsReducer,

  // Timers

  timeCheck: timeCheckReducer,
  bcastDuration: bcastDurationReducer,
  bcastCountdown: bcastCountdownReducer,

  // Paint

  // - Current Stream

  paintBcastStream: paintBcastStreamReducer,
  paintBcastStreamDraw: paintBcastStreamDrawReducer,
  paintBcastStreamPic: paintBcastStreamPicReducer,
  paintBcastStreamVideo: paintBcastStreamVideoReducer,

  // - Live Paint

  livePaint: livePaintReducer,
  livePaintDraw: livePaintDrawReducer,
  livePaintVideo: LivePaintVideoReducer,

  // ScreenShot

  screenShot: screenShotReducer,

  // Image to share

  imageToShare: imageToShareReducer,

  // Communitcation Channel

  commChannel: commChannelReducer,

  // Sturn/Turn credentials

  stunTurnCredentials: stunTurnCredentialsReducer,

  // Network Congestion

  networkCongestionPub: NetworkCongestionPubReducer,
  networkCongestionSub: NetworkCongestionSubReducer,

  // Viewers info

  viewers: viewersReducer,

  // Feedback

  feedbackEndBcast: feedbackEndBcastReducer,

  // Stream context

  streamContext: streamContextReducer,

  // Store duration

  storeDuration: storeDurationReducer,

  // ####################################################################################################
  // ~~~~~~ Stream Manager
  // ####################################################################################################

  // Server

  streamManagerServerPubMain: streamManagerServerPubMainReducer,
  streamManagerServerPubCamMic: streamManagerServerPubCamMicReducer,

  streamManagerServerSubMain: streamManagerServerSubMainReducer,
  streamManagerServerSubCamMic: streamManagerServerSubCamMicReducer,

  // Red5Pro Subscription/Publication (viewer/broadcasting)

  streamManagerPubMain: streamManagerPubMainReducer,
  streamManagerPubCamMic: streamManagerPubCamMicReducer,

  streamManagerSubMain: streamManagerSubMainReducer,
  streamManagerSubCamMic: streamManagerSubCamMicReducer,

  // ####################################################################################################
  // ~~~~~~ Store
  // ####################################################################################################

  storePlan: storePlanReducer,
  storeProducts: storeProductsReducer,

  // ####################################################################################################
  // ~~~~~~ Ai
  // ####################################################################################################

  aiConfig: aiConfigReducer,
  aiDescription: aiDescriptionReducer,
  aiOcr: aiOcrReducer,
  aiTranslation: aiTranslationReducer,
  aiTts: aiTtsReducer,
}

// ~~~~~~

export default reducers
