import { ofType, StateObservable } from 'redux-observable'
import { Observable, filter, map } from 'rxjs'
import { StoreState } from '../../../../../models/app/model'
import { UtilsString } from '@dn/utils'
import {
  EpicShareMainStreamGetStreamByExtensionMC,
  EpicShareMainStreamGetStreamByExtensionMT,
} from '../../../share-main-stream/get-stream-by-extension/mutators'
import { ApiBcastStreamContextAC } from '../../../../actions/api-bcast/stream-context/actions'
import { Config } from '../../../../../config'
import { ConstBcast } from '../../../../../constants/bcast'

type Action = ReturnType<typeof EpicShareMainStreamGetStreamByExtensionMC.ok>

export const deprecatedChainingPubMainFromGetStreamByExtensionToCreateStreamContextId$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(EpicShareMainStreamGetStreamByExtensionMT.OK),

    filter(() => !Config.Features.BcastService2),

    map(() => {
      // Gen broadcast ID

      const bcastId = UtilsString.genRandomStr(ConstBcast.IdLength, {
        symbols: false,
        numbers: true,
        letters: false,
      })

      // Create stream context

      return ApiBcastStreamContextAC.create(bcastId)
    }),
  )
