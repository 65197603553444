import { EMPTY, filter, map, mergeMap, Observable, of, takeUntil } from 'rxjs'
import { ApiAiAC, ApiAiAT } from '../../../../actions/api-ai/actions'
import { ofType, StateObservable } from 'redux-observable'
import { StoreState } from '../../../../../models/app/model'
import { apiAiOcrCreate$ } from '../../../../../services/api-ai/ocr/create'
import { ApiUtils } from '../../../utils'
import { EpicApiIaOcrCreateMC } from './mutators'
import { cancelAll$$ } from '../../../../../subjects/cancel-all/subject'
import { Config } from '../../../../../config'

type Action = ReturnType<typeof ApiAiAC.ocrCreate>

type OcrData = DN.Models.Ai.Ocr.Model[0]['data']

const checkIsOcrEq = (newOcr: OcrData, lastOcr?: OcrData) => {
  if (!lastOcr) return false

  if (newOcr.lines.length !== lastOcr.lines.length) return false

  return newOcr.lines.every((line, idx) => line.text === lastOcr.lines[idx].text)
}

export const apiAiOcrCreateEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(ApiAiAT.OCR_CREATE),

    filter(() => Config.Features.AI && state$.value.aiConfig.ocr !== 'disabled'),

    mergeMap(({ payload }) =>
      apiAiOcrCreate$(payload.imgBlob).pipe(
        takeUntil(cancelAll$$),

        map((res) => ({
          res,
          idAiReq: payload.idAiReq,
        })),
      ),
    ),

    mergeMap((data) => {
      if (!ApiUtils.isSuccess(data.res)) {
        return of(EpicApiIaOcrCreateMC.error(data.res.response))
      }

      const lastOcr = state$.value.aiOcr.at(-1)

      const newOcr = data.res.response

      if (checkIsOcrEq(newOcr, lastOcr?.data)) {
        return EMPTY
      }

      return of(EpicApiIaOcrCreateMC.ok(data.idAiReq, newOcr))
    }),
  )
