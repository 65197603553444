import styled from 'styled-components'
import { Fonts } from '../../../style/theme/fonts'

// ~~~~~~ Constants

export const ClassName = 'dialog-ai-config'

// ~~~~~~ Component

export const STDialogAiConfig = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 16px;
  margin-bottom: 16px;

  .${ClassName}--content {
    ${Fonts.Scale.BodyBig};

    display: flex;
    flex-direction: column;

    gap: 16px;

    .${ClassName}--setting {
      display: flex;
      align-items: center;

      gap: 16px;

      .${ClassName}--setting--switch {
      }

      .${ClassName}--setting--info {
        &.disabled {
          opacity: 0.5;
        }
      }
    }
  }
`
