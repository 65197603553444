import { guardIsPredefMessage } from '../is-predef-message/guard'

const id: DN.Services.BcastDomain.Messages.PrefedMessage.ConfirmCloseNoViewers['payload']['id'] =
  'confirm-close-no-viewers'

export const guardIsConfirmCloseNoViewers = (
  message: unknown,
): message is DN.Services.BcastDomain.Messages.PrefedMessage.ConfirmCloseNoViewers => {
  if (!guardIsPredefMessage(message)) return false

  if (!('id' in message.payload) || !message.payload.id || message.payload.id !== id) return false

  return true
}
