const prefix = 'mt-api-ia-tts-create'

// ~~~~~~ Mutators Types

export const EpicApiIaTtsCreateMT = {
  OK: `${prefix}-ok`,
  ERROR: `${prefix}-error`,
} as const

// ~~~~~~ Mutators Creators

export const EpicApiIaTtsCreateMC = {
  ok: (
    idAiReq: DN.Models.Ai.ReqId,
    sentenceId: UUID,
    sentenceText: string,
    data: DN.Models.Ai.Tts.Api.Create.ResParsed,
  ) => ({
    type: EpicApiIaTtsCreateMT.OK,
    payload: {
      idAiReq,
      sentenceId,
      sentenceText,
      data,
    },
  }),

  error: (errors: Infos[]) => ({
    type: EpicApiIaTtsCreateMT.ERROR,
    payload: {
      errors,
    },
  }),
} as const
