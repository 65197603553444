import React from 'react'
import { useLayoutTopContentContext } from '../../../hooks/use-layout-top-content-context'

// ~~~~~~ Component

export const LayoutTopContentTopRight: React.FC<any> = ({ children }) => {
  const { kind } = useLayoutTopContentContext()

  return <>{kind === 'top-right' ? children : null}</>
}
