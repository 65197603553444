import { useEffect, useState } from 'react'
import { ModalsIds } from '../../../constants/modals'
import { genAnimatedDialog } from '../compound-dialog/dialog/component'
import { useSelector } from 'react-redux'
import { StoreState } from '../../../models/app/model'
import { DialogBreakPoint } from '../../../constants/breakpoints'
import { Content } from '../compound-dialog/content/component'
import { Actions } from '../compound-dialog/actions/component'
import { Button } from '../../common/button/component'
import { ClassName, STDialogAiConfig } from './style'
import { SliderCheck } from '../../common/slider-check/component'
import { AiFieldsMR } from '../../../models/ai/model'
import { Trans } from '../../common/intl/trans'
import { useDispatch } from 'react-redux'
import { AiTimerAC } from '../../../store/actions/ai-timer/actions'

// ~~~~~~ Constants

const ModalId = ModalsIds.AiConfig

const Dialog = genAnimatedDialog(ModalId)

const AiMC = AiFieldsMR.__model__.MC

// ~~~~~~ Helpers

const checkAiIsFullDisabled = (aiConfig: StoreState['aiConfig']) => {
  return aiConfig.ocr === 'disabled' && !aiConfig.description && !aiConfig.tts
}

// ~~~~~~ Component

export const DialogAiConfig = () => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  // ~~~~~~ State

  // - Local

  const [doClose, setDoClose] = useState(0)

  const [originalState, setOriginalState] = useState<StoreState['aiConfig'] | null>(null)

  // - Store

  const aiConfig = useSelector((state: StoreState) => state.aiConfig)

  // ~~~~~~ Computed

  const curIsFullDisabled = originalState ? checkAiIsFullDisabled(originalState) : true

  const newIsFullDisabled = checkAiIsFullDisabled(aiConfig)

  const showStart = curIsFullDisabled && aiConfig.uiStatus === 'init'

  const showStop = newIsFullDisabled && aiConfig.uiStatus === 'running'

  // ~~~~~~ Handlers

  function onClose() {
    setDoClose(performance.now())
  }

  function onChangeOcr() {
    switch (aiConfig.ocr) {
      case 'disabled':
        dispatch(AiMC.setModel({ ocr: 'enabled-only' }))
        break

      case 'enabled-with-translation':
      case 'enabled-only':
        dispatch(AiMC.setModel({ ocr: 'disabled' }))
        break
    }
  }

  function onChangeOcrTranslation() {
    switch (aiConfig.ocr) {
      case 'disabled':
        dispatch(AiMC.setModel({ ocr: 'enabled-with-translation' }))
        break

      case 'enabled-with-translation':
        dispatch(AiMC.setModel({ ocr: 'enabled-only' }))
        break

      case 'enabled-only':
        dispatch(AiMC.setModel({ ocr: 'enabled-with-translation' }))
        break
    }
  }

  function onChangeTts() {
    dispatch(AiMC.setModel({ tts: !aiConfig.tts }))
  }

  function onChangeDescription() {
    dispatch(AiMC.setModel({ description: !aiConfig.description }))
  }

  function onWillBeClosed() {
    if (!originalState) return

    // Same, do nothing

    if (Object.entries(aiConfig).toString() === Object.entries(originalState).toString()) {
      return
    }

    // Disable

    if (checkAiIsFullDisabled(aiConfig)) {
      setTimeout(() => dispatch(AiTimerAC.stop()), 100)

      return
    }

    // Enable

    if (checkAiIsFullDisabled(originalState)) {
      setTimeout(() => dispatch(AiTimerAC.start()), 100)

      return
    }
  }

  // ~~~~~~ Effects

  // - On mount save current state

  useEffect(() => {
    setOriginalState(structuredClone(aiConfig))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // ~~~~~~ Render

  return (
    <Dialog
      stackedBtns={true}
      modalSize={DialogBreakPoint.S}
      dialogTitle="wildcard"
      dialogTitleValues={{ value: 'Ai Configuration' }}
      closeDialog={doClose}
      onWillBeClosed={onWillBeClosed}
    >
      <Content>
        <STDialogAiConfig>
          <div className={`${ClassName}--content`}>
            {/* OCR */}

            <div className={`${ClassName}--setting`}>
              <div className={`${ClassName}--setting--switch`}>
                <SliderCheck onChange={onChangeOcr} checked={aiConfig.ocr !== 'disabled'} />
              </div>

              <div className={`${ClassName}--setting--info`}>
                <Trans id="wildcard" values={{ value: 'Optical character recognition' }} />
              </div>
            </div>

            {/* Translate */}

            <div className={`${ClassName}--setting`}>
              <div className={`${ClassName}--setting--switch`}>
                <SliderCheck
                  $disabled={aiConfig.ocr === 'disabled'}
                  onChange={onChangeOcrTranslation}
                  checked={aiConfig.ocr === 'enabled-with-translation'}
                />
              </div>

              <div
                className={`${ClassName}--setting--info ${aiConfig.ocr === 'disabled' ? 'disabled' : ''}`}
              >
                <Trans id="wildcard" values={{ value: 'Translate recognized text' }} />
              </div>
            </div>

            {/* TTS */}

            <div className={`${ClassName}--setting`}>
              <div className={`${ClassName}--setting--switch`}>
                <SliderCheck onChange={onChangeTts} checked={aiConfig.tts} />
              </div>

              <div className={`${ClassName}--setting--info`}>
                <Trans id="wildcard" values={{ value: 'Text to speech' }} />
              </div>
            </div>

            {/* Description */}

            <div className={`${ClassName}--setting`}>
              <div className={`${ClassName}--setting--switch`}>
                <SliderCheck onChange={onChangeDescription} checked={aiConfig.description} />
              </div>

              <div className={`${ClassName}--setting--info`}>
                <Trans id="wildcard" values={{ value: 'Scene description' }} />
              </div>
            </div>
          </div>

          {/* - */}
        </STDialogAiConfig>
      </Content>

      {/* Actions */}

      <Actions>
        <Button
          $colorType={showStart ? 'primary' : showStop ? 'error' : 'secondary'}
          intlId={'wildcard'}
          intlValues={{ value: showStart ? 'Start' : showStop ? 'Stop' : 'Close' }}
          $active={true}
          $running={false}
          disabled={false}
          onClick={onClose}
        />
      </Actions>
    </Dialog>
  )
}
