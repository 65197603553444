import { TtsOpts } from '../../../services/api-ai/text-to-speech/create'

const prefix = 'at-api-ai'

// ~~~~~~ Action Types

export const ApiAiAT = {
  DESCRIPTION_CREATE: `${prefix}-description-create`,

  OCR_CREATE: `${prefix}-ocr-create`,

  TRANSLATION_CREATE: `${prefix}-tranlation-create`,

  TTS_CREATE: `${prefix}-tts-create`,
} as const

// ~~~~~~ Action Creators

export const ApiAiAC = {
  descriptionCreate: (idAiReq: DN.Models.Ai.ReqId, imgBlob: Blob) => ({
    type: ApiAiAT.DESCRIPTION_CREATE,
    payload: {
      idAiReq,
      imgBlob,
    },
  }),

  ocrCreate: (idAiReq: DN.Models.Ai.ReqId, imgBlob: Blob) => ({
    type: ApiAiAT.OCR_CREATE,
    payload: {
      idAiReq,
      imgBlob,
    },
  }),

  translationCreate: (
    idAiReq: DN.Models.Ai.ReqId,
    sentences: DN.Models.Ai.Sentence[],
    lang?: string,
  ) => ({
    type: ApiAiAT.TRANSLATION_CREATE,
    payload: {
      idAiReq,
      sentences,
      lang,
    },
  }),

  ttsCreate: (
    idAiReq: DN.Models.Ai.ReqId,
    sentence: DN.Models.Ai.Sentence,
    opts?: Partial<TtsOpts>,
  ) => ({
    type: ApiAiAT.TTS_CREATE,
    payload: {
      idAiReq,
      sentence,
      opts,
    },
  }),
} as const
