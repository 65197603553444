import { Observable, of, mergeMap, filter } from 'rxjs'
import { StateObservable, ofType } from 'redux-observable'
import { ConstWebRtc } from '@dn/constants'
import { ServiceWebRtc } from '@dn/webrtc'
import { Config } from '../../../../../config'
import { StoreState } from '../../../../../models/app/model'
import { BroadcastAC } from '../../../../actions/bcast/actions'
import { CancelNowAC, CancelReasons } from '../../../../actions/cancel-now/actions'
import {
  EpicApiServerPubMainMC,
  EpicApiServerPubMainMT,
} from '../../../api-stream-manager/server/pub-main/mutators'

type Action = ReturnType<typeof EpicApiServerPubMainMC.ok>

export const deprecatedChainingPubMainFromGetServerPubMainToStartPub$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(EpicApiServerPubMainMT.OK),

    filter(() => !Config.Features.BcastService2),

    mergeMap(({ payload }) => {
      const { sharedMainStream, subscription } = state$.value

      const { curScreenStream, curScreenVideoTrack } = sharedMainStream

      if (!curScreenStream) {
        return of(CancelNowAC.cancelAll([CancelReasons.NoScreenStreamFound]))
      }

      const screenStreamConstraints = ConstWebRtc.Constraints.Screen[subscription.quality]

      if (!screenStreamConstraints.video) {
        return of(CancelNowAC.cancelAll([CancelReasons.NoStreamQualityFound]))
      }

      const videoBitrate = ServiceWebRtc.Codecs.calcH264Bandwidth({
        h264Profile: 'high',
        width: screenStreamConstraints.video.width,
        height: screenStreamConstraints.video.height,
        frameRate: subscription.fps,
      })

      if (!curScreenVideoTrack) {
        return of(CancelNowAC.cancelAll([CancelReasons.NoScreenStreamFound]))
      }

      const { server, stunTurnCredentials } = payload

      const action = BroadcastAC.startPubMain(
        // pubConfig
        {
          serverAddress: server.serverAddress,
          broadcastId: server.broadcastId,
          host: Config.Api.StreamManager.URI,
          videoBitrate,
          audioBitrate: 0,
          mediaElementId: 'main-stream-pub',
        },

        // screen stream
        curScreenStream,

        // userPassToken
        {
          username: 'username',
          password: 'password',
          token: subscription.token,
        },

        // iceServers
        [
          {
            urls: stunTurnCredentials.uris,
            username: stunTurnCredentials.username,
            credential: stunTurnCredentials.password,
          },
        ],
      )

      return of(action)
    }),
  )
