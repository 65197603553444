import { motion } from 'framer-motion'
import React from 'react'
import { STSliderCheck, STProps } from './style'

// ~~~~~~ Constants

const spring = {
  type: 'spring',
  stiffness: 700,
  damping: 30,
}

// ~~~~~~ Type

type Props = STProps & {
  checked: boolean
  onChange?: (evt?: any) => void
}

// ~~~~~~ Component

export const SliderCheck: React.FC<Props> = ({
  checked,

  // Style
  $small: small,
  $colorType: colorType,
  $disabled,

  onChange,
}) => {
  // ~~~~~~ handlers

  function onChangeSelf(evt?: any) {
    if ($disabled) return

    onChange && onChange(evt)
  }

  // ~~~~~~ Render

  return (
    <STSliderCheck
      data-checked={checked}
      $small={small}
      $colorType={colorType}
      $disabled={$disabled}
    >
      <div className="slider-check-switch" data-checked={checked} onClick={onChangeSelf}>
        <motion.div className="slider-check-checkbox" layout transition={spring} />
      </div>
    </STSliderCheck>
  )
}
