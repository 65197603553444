import { UtilsStore } from '@dn/utils'
import { AppInitState } from '../../../../models/app/model'
import {
  EpicApiIaTranslationCreateMC,
  EpicApiIaTranslationCreateMT,
} from '../../../epics/api-ai/translation/create/mutators'
import { AiFieldsMR } from '../../../../models/ai/model'
import { AiTimerAT } from '../../../actions/ai-timer/actions'
import { EpicApiIaOcrCreateMT } from '../../../epics/api-ai/ocr/create/mutators'

const initState = AppInitState.aiTranslation

type State = typeof initState

const reducer: any = {}

// ####################################################################################################
// ~~~~~~ Create api OK
// ####################################################################################################

reducer[EpicApiIaOcrCreateMT.OK] = (state: State): State => {
  return initState
}

// ####################################################################################################
// ~~~~~~ Create api OK
// ####################################################################################################

reducer[EpicApiIaTranslationCreateMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof EpicApiIaTranslationCreateMC.ok>,
): State => {
  return [
    {
      counter: payload.idAiReq.counter,
      id: payload.idAiReq.id,
      data: payload.data,
    },
  ]
}

// ####################################################################################################
// ~~~~~~ Config changed
// ####################################################################################################

reducer[AiFieldsMR.__model__.MT.SET_MODEL] = (
  state: State,
  { payload }: ReturnType<typeof AiFieldsMR.__model__.MC.setModel>,
): State => {
  return !payload.ocr || payload.ocr === 'enabled-with-translation' ? state : initState
}

// ####################################################################################################
// ~~~~~~ Timer STOP
// ####################################################################################################

reducer[AiTimerAT.STOP] = (state: State): State => {
  return initState
}

// ~~~~~~

export const aiTranslationReducer = UtilsStore.dynReducer(initState, reducer)
