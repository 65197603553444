import styled from 'styled-components'

export const STDebugLang = styled.div`
  position: absolute;

  z-index: 9999;

  width: 100px;
  height: 60px;
  border-radius: 3px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #fff;
  border: solid 1px #aaa;
`
