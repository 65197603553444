import { useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'

// ~~~~~~ Constants

const DOMElementName = 'side-menu-root'

// ~~~~~~ Types

type Props = {
  children: React.ReactNode
}

// ~~~~~~ Component

export const SideMenuPortal: React.FC<Props> = ({ children }) => {
  // ~~~~~~ State

  const [mounted, setMounted] = useState(false)

  const mainElementRef = useRef<HTMLElement | null>(null)

  // ~~~~~~ Effects

  // Get the DOM node of the portal
  useEffect(() => {
    if (mounted) return

    mainElementRef.current = document.getElementById(DOMElementName)

    setMounted(true)

    //
  }, [mounted])

  // ~~~~~~ Portal

  return mounted && mainElementRef.current
    ? ReactDOM.createPortal(children, mainElementRef.current)
    : null
}
