import {
  fromEvent,
  merge,
  Observable,
  of,
  catchError,
  filter,
  map,
  mergeMap,
  take,
  takeUntil,
  tap,
} from 'rxjs'
import { ConstWebRtc } from '@dn/constants'
import { ServiceWebRtc } from '@dn/webrtc'
import { ofType, StateObservable } from 'redux-observable'
import { StoreState } from '../../../../models/app/model'
import { ShareMainStreamAC, ShareMainStreamAT } from '../../../actions/share-main-stream/actions'
import { CancelNowAC, CancelReasons } from '../../../actions/cancel-now/actions'
import { EpicShareMainStreamGetStreamByExtensionMC } from './mutators'
import { ShareMainStream$$ } from '../subject'
import { ExtensionService } from '../../../../services/extension'
import { UtilsLog } from '../../../../utils/logs'
import { cancelAll$$ } from '../../../../subjects/cancel-all/subject'
import { ServiceBcast } from '@dn/bcast'
import { Config } from '../../../../config'

type Action = ReturnType<typeof ShareMainStreamAC.getStreamByExtension>

export const shareMainStreamGetStreamByExtensionEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(ShareMainStreamAT.GET_STREAM_BY_EXTENSION),

    mergeMap(({ payload }) =>
      ExtensionService.SendMessage.ToExtension.checkExtension$().pipe(
        mergeMap(() => ExtensionService.SendMessage.ToExtension.chooseSource$(payload.kind)),

        mergeMap(({ streamId }) => {
          const subscription = state$.value.subscription

          return ServiceWebRtc.Media.Screen.getChromeExtensionStream$(
            streamId,
            payload.kind,
            subscription.quality,
            subscription.fps,
          ).pipe(
            takeUntil(cancelAll$$),
            tap(() => ExtensionService.SendMessage.ToExtension.setMeAsActive()),
          )
        }),

        mergeMap((stream) => {
          const [videoTracks] = stream && stream.getVideoTracks ? stream.getVideoTracks() : []

          if (!videoTracks) {
            return of(CancelNowAC.cancelAll([CancelReasons.ScreenStreamHasNotAMediaTrack]))
          }

          const replaceTrack$ = fromEvent(videoTracks, ConstWebRtc.Media.Track.Events.Ended).pipe(
            take(1),

            takeUntil(cancelAll$$),

            takeUntil(ShareMainStream$$.pipe(filter((msg) => msg.type === 'replace-track'))),

            map(() => CancelNowAC.cancelAll([CancelReasons.ScreenSharingEnded])),

            catchError(() =>
              of(CancelNowAC.cancelAll([CancelReasons.ScreenStreamHasNotAMediaTrack])),
            ),
          )

          const actions = [
            // Listening change replace track

            replaceTrack$,

            // Add to bcast service

            Config.Features.BcastService2
              ? of(ServiceBcast.React.Mutators.MC.addScreenStream(stream))
              : undefined,

            // Ok

            of(EpicShareMainStreamGetStreamByExtensionMC.ok(stream, videoTracks)),

            //
          ].filter(Boolean)

          return merge(...actions)
        }),

        catchError((err: any) => {
          UtilsLog.devLog('webrtcExtensionChooseSourceEpic$', 'ERR', err)

          const errorCode: DN.Services.Webrtc.ErrorCode =
            typeof err === 'object' && err.canceled
              ? 'user-permission-denied'
              : 'system-permission-dismissed'

          return of(
            EpicShareMainStreamGetStreamByExtensionMC.error([
              { id: 'wildcard', values: { value: errorCode } },
            ]),
          )
        }),
      ),
    ),

    tap(() => {
      ExtensionService.SendMessage.ToExtension.chooseSourceStateChanged('not-choosing')
    }),
  )
