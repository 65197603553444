import { UtilsStore } from '@dn/utils'
import { AppInitState } from '../../../../models/app/model'
import {
  EpicApiIaTtsCreateMC,
  EpicApiIaTtsCreateMT,
} from '../../../epics/api-ai/tts/create/mutators'
import { AiFieldsMR } from '../../../../models/ai/model'
import { AiTimerAT } from '../../../actions/ai-timer/actions'
import { AiMT, AiMC } from '../../../actions-mutators/ai/mutators'
import { EpicApiIaOcrCreateMT } from '../../../epics/api-ai/ocr/create/mutators'

const initState = AppInitState.aiTts

type State = typeof initState

const reducer: any = {}

// ####################################################################################################
// ~~~~~~ Create api OK
// ####################################################################################################

reducer[EpicApiIaOcrCreateMT.OK] = (state: State): State => {
  return initState
}

// ####################################################################################################
// ~~~~~~ Create api OK
// ####################################################################################################

reducer[EpicApiIaTtsCreateMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof EpicApiIaTtsCreateMC.ok>,
): State => {
  return [
    ...state,

    {
      counter: payload.idAiReq.counter,
      id: payload.idAiReq.id,
      sentenceId: payload.sentenceId,
      sentenceText: payload.sentenceText,
      audio: payload.data,
    },
  ]
}

// ####################################################################################################
// ~~~~~~ Config changed
// ####################################################################################################

reducer[AiFieldsMR.__model__.MT.SET_MODEL] = (
  state: State,
  { payload }: ReturnType<typeof AiFieldsMR.__model__.MC.setModel>,
): State => {
  return payload.tts === undefined || payload.tts ? state : initState
}

// ####################################################################################################
// ~~~~~~ Timer STOP
// ####################################################################################################

reducer[AiTimerAT.STOP] = (state: State): State => {
  return initState
}

// ####################################################################################################
// ~~~~~~ Remove audio
// ####################################################################################################

reducer[AiMT.REMOVE_AUDIO] = (
  state: State,
  { payload }: ReturnType<typeof AiMC.removeAudio>,
): State => {
  return state.filter((tts) => tts.sentenceId !== payload.id)
}

// ~~~~~~

export const aiTtsReducer = UtilsStore.dynReducer(initState, reducer)
