import styled from 'styled-components'
import { Fonts } from '../../../../../../style/theme/fonts'
import { Colors } from '../../../../../../style/theme/colors'
import { theme } from '../../../../../../style/utils/theme'
import { HexAlpha } from '../../../../../../services/draw/constants/draw-partial-service'
import { Transition } from '../../../../../../style/animations/css-animations'

// ~~~~~~ Constants

export const ClassName = 'joined-desktop-content-main-ai-info'

// ~~~~~~ Component

export const STJoinedDesktopContentMainAiInfo = styled.div`
  /* border: solid 1px royalblue; */

  /* Description cannot be clicked */
  /* pointer-events: none; */

  position: absolute;
  z-index: 10;

  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  .${ClassName}--video {
    /* border: solid 1px royalblue; */

    position: relative;

    .${ClassName}--video--frame {
      /* opacity: 0.5; */

      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;

      /* border: solid 1px papayawhip; */

      z-index: 1;

      .${ClassName}--video--frame--line {
        position: absolute;

        background-color: ${theme(Colors.WOLF_95_95)}${HexAlpha[90]};

        line-height: normal;

        padding-left: 8px;
        padding-right: 8px;

        border-radius: 4px;

        ${Transition.ColorFaster};

        &.playing {
          z-index: 2;
          color: ${theme(Colors.BRONX_50_50)};
        }
      }

      .${ClassName}--video--frame--desc {
        position: absolute;
        right: 8px;
        bottom: 8px;

        width: 256px;
        padding-top: 12px;
        padding-right: 12px;

        border-radius: 4px;

        background-color: ${theme(Colors.WOLF_95_95)}${HexAlpha[90]};

        .${ClassName}--video--frame--desc--title {
          padding-left: 12px;

          ${Fonts.Scale.BodyBig};

          margin-bottom: 8px;
        }

        .${ClassName}--video--frame--desc--text {
          padding-left: 12px;

          ${Fonts.Scale.Body}
          ${Fonts.Weight[400]}

          margin-bottom: 8px;
        }

        .${ClassName}--video--frame--desc--actions {
          height: 32px;
          width: 100%;

          display: flex;
          justify-content: flex-end;

          .${ClassName}--video--frame--desc--actions--action {
            cursor: pointer;

            display: flex;
            align-items: center;
            justify-content: center;

            svg {
              width: 14px;
              height: 14px;

              margin-right: auto;

              path {
                ${Transition.FillFaster}

                fill: ${theme(Colors.WOLF_05_05)};
                stroke: unset;
              }
            }

            &:hover {
              svg {
                path {
                  fill: ${theme(Colors.BRONX_50_50)};
                }
              }
            }
          }
        }
      }
    }

    .${ClassName}--video--frame--action {
      cursor: pointer;

      position: absolute;

      right: 8px;
      bottom: 8px;

      height: 32px;
      width: 32px;

      border-radius: 4px;

      background-color: ${theme(Colors.WOLF_95_95)}${HexAlpha[90]};

      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 14px;
        height: 14px;

        path {
          ${Transition.FillFaster};

          fill: ${theme(Colors.WOLF_05_05)};
          stroke: unset;
        }
      }

      &:hover {
        svg {
          path {
            fill: ${theme(Colors.BRONX_50_50)};
          }
        }
      }
    }
  }
`
