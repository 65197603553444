import { useIntl } from 'react-intl'
import { UtilsLog } from '../utils/logs'

// ~~~~~~ Hook

export const useTrans = (id: IntlMsgId, values?: { [key: string]: any }) => {
  // ~~~~~~ Hooks

  const intl = useIntl()

  // ~~~~~~ Computed

  const text = intl.formatMessage({ id }, values)

  // ~~~~~~ Render

  if (typeof text !== 'string') {
    UtilsLog.devWarn('useTrans not returning a String')

    return id
  }

  return text
}
