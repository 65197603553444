import { Observable, catchError, map, of } from 'rxjs'
import { ajax } from 'rxjs/ajax'
import { AiHeaderUtils } from '../utils/headers'
import { AiRoutes } from '../constants/routes'

const { Create } = AiRoutes.Description

// ####################################################################################################
// ~~~~~~ Description: From image blog to Description Data
// ####################################################################################################

type Res = DN.Models.Ai.Description.Api.Create.Res
type ResParsed = DNApiResParsed<DN.Models.Ai.Description.Api.Create.ResParsed>
type ResError = DNApiError<Infos[]>

export const apiAiDescriptionCreate$ = (imgBlob: Blob, headers?: AppHeaders) => {
  //

  const body: DN.Models.Ai.Description.Api.Create.Req = imgBlob

  return ajax<Res>({
    ...AiHeaderUtils.genDescriptionHeaders(headers),
    method: Create.Method,
    url: Create.getUrl(),
    body,
  }).pipe(
    map((res): ResParsed => {
      return {
        status: res.status,
        response: res.response.description,
      }
    }),

    catchError((res: ResError): Observable<ResError> => {
      return of({
        status: res.status,
        response: [{ id: 'wildcard', values: { value: `ERR IA Description: ${res.response}` } }],
      })
    }),
  )
}
