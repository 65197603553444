import { UtilsLang, UtilsString } from '@dn/utils'
import { Config } from '../config'
import { LocalStorageLang } from '../services/storage/local/lang'

// ~~~~~ Types

export type GetMessagesErr = Error & { lang: string }

// ~~~~~ Constants

const { DefaultLocale, SupportedLocales } = Config.Intl

const StoredLang = LocalStorageLang.get()

const locale: Lang = UtilsLang.getAppLang(SupportedLocales, DefaultLocale, StoredLang)

// ~~~~~~

export const getMessages = (lang = locale, remDefaults?: true) => {
  return import(`./${lang}/intl.json`)
    .then((messages) => UtilsLang.flattenMessages(messages))
    .then((messages) => {
      if (!remDefaults) {
        return {
          locale: lang,
          messages,
        }
      }

      const messagesCleared = Object.keys(messages).reduce((acc, cur) => {
        return UtilsString.startWithStr(cur, 'default.')
          ? acc
          : {
              ...acc,
              [cur]: messages[cur],
            }
      }, {})

      return {
        locale: lang,
        messages: messagesCleared,
      }
    })
    .catch((err) => {
      err.lang = lang
      throw err
    })
}
