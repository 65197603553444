import { combineEpics } from 'redux-observable'

import { ServiceBcast } from '@dn/bcast'

import { apiBcastCurrentUserShowEpic$ } from './api-bcast/current-user/show/epic'
import { apiBcastGoogleClassRoomAnnounceBcastLinkEpic$ } from './api-bcast/google-classroom/announce-bcast-link/epic'
import { apiBcastGoogleClassRoomAnnounceCaptureEpic$ } from './api-bcast/google-classroom/announce-capture/epic'
import { apiBcastGoogleClassRoomListEpic$ } from './api-bcast/google-classroom/list/epic'
import { apiBcastGoogleSlidesAddImageSlideEpic$ } from './api-bcast/google-slides/add-image-slide/epic'
import { apiBcastGoogleSlidesCreateAndAddImageToSlideEpic$ } from './api-bcast/google-slides/create-presentation-and-add-image-slide/epic'
import { apiBcastGoogleSlidesCreatePresentationEpic$ } from './api-bcast/google-slides/create-presentation/epic'
import { apiBcastGoogleSlidesListPresentationsEpic$ } from './api-bcast/google-slides/list-presentations/epic'
import { apiBcastProvidersLinkGoogleEpic$ } from './api-bcast/providers/link-google/epic'
import { apiBcastProvidersUnlinkGoogleEpic$ } from './api-bcast/providers/unlink-google/epic'
import { apiBcastRoomForPubEpic$ } from './api-bcast/room/pub/epic'
import { apiBcastRoomForSubEpic$ } from './api-bcast/room/sub/epic'
import { apiBcastStreamContextCreateEpic$ } from './api-bcast/stream-context/create/epic'
import { apiBcastStreamContextShowEpic$ } from './api-bcast/stream-context/show/epic'
import { apiBcastSubscriptionShowEpic$ } from './api-bcast/subscription/show/epic'
import { apiBcastUserSettingsDeleteEpic$ } from './api-bcast/user-settings/delete/epic'
import { apiBcastUserSettingsShowEpic$ } from './api-bcast/user-settings/show/epic'
import { apiBcastUserSettingsUpdateEpic$ } from './api-bcast/user-settings/update/epic'
import { apiStoreCheckoutCancelEpic$ } from './api-store/checkout/cancel/epic'
import { apiStoreCheckoutCreateSessionEpic$ } from './api-store/checkout/create-session/epic'
import { apiStoreCheckoutSendEmailEpic$ } from './api-store/checkout/send-email-pass/epic'
import { apiStoreCheckoutValidateEmailCodeEpic$ } from './api-store/checkout/validate-email-code/epic'
import { apiStoreProductsListEpic$ } from './api-store/products/list/epics'
import { apiStoreSubscriptionCheckPaymentMethodEpic$ } from './api-store/subscription/check-payment-method/epic'
import { apiStoreSubscriptionGetTimeEpic$ } from './api-store/subscription/get-time/epic'
import { apiStoreSubscriptionGetTimeOnGetScreenEpic$ } from './api-store/subscription/get-time/on-get-screen'
import { apiStoreSubscritionsCreateEpic$ } from './api-store/subscription/create/epic'
import { apiStoreSubscritionsUpgradePlanEpic$ } from './api-store/subscription/upgrade-plan/epic'
import { apiStreamManagerServerPubCamGetEpic$ } from './api-stream-manager/server/pub-cam-mic/epic'
import { apiStreamManagerServerPubMainGetEpic$ } from './api-stream-manager/server/pub-main/epic'
import { apiStreamManagerServerSubCamMicGetEpic$ } from './api-stream-manager/server/sub-cam-mic/epics'
import { apiStreamManagerServerSubMainGetEpic$ } from './api-stream-manager/server/sub-main/epics'
import { bcastCommChannelInitEpic$ } from './comm-channel/init/epic'
import { bcastCommChannelMessagesFromServerEpic$ } from './comm-channel/messages/from-server/epic'
import { bcastExitEpic$ } from './bcast/exit/epic'
import { bcastStartPubCamMicEpic$ } from './bcast/start-pub-cam-mic/epic'
import { bcastStartPubMainEpic$ } from './bcast/start-pub-main/epic'
import { bcastStartSubCamMicEpic$ } from './bcast/start-sub-cam-mic/epic'
import { bcastStartSubMainEpic$ } from './bcast/start-sub-main/epic'
import { cancelNowAllEpic$ } from './cancel-now/all/epic'
import { cancelNowCamMicEpic$ } from './cancel-now/cam-mic/epic'

import { chainingEventsFromRoomPubErrorToExitAndCancel$ } from './@chaining/pub-main/events/from-room-pub-error-to-exit-and-cancel'
import { chainingEventsFromRoomSubConnectedToExitAndCancel$ } from './@chaining/sub-main/events/from-room-sub-connected-to-exit-and-cancel'
import { chainingFromGetStreamToRoomForPub$ } from './@chaining/pub-main/1-from-get-stream-to-room-for-pub'
import { chainingfromRoomForPubToConnect$ } from './@chaining/pub-main/2-from-room-for-pub-to-connect'
import { chainingFromRoomForPubToStartViewer$ } from './@chaining/sub-main/1-from-room-for-sub-to-start-viewer'
import { chainingPubCamMicFromGetServerCamMicPubToStartPubEpic$ } from './@chaining/pub-cam-mic/2-from-get-server-cam-mic-pub-to-start-pub'
import { chainingPubCamMicFromShareCamMicStreamGetStreamToGetServerPubCamMicEpic$ } from './@chaining/pub-cam-mic/1-from-share-cam-mic-stream-get-stream-to-get-server-pub-cam-mic'
import { chainingStoreDurationFromStartPubMain$ } from './@chaining/store-duration/1-from-start-pub-main-to-empty'
import { chainingStoreDurationFromStartSubMain$ } from './@chaining/store-duration/1-from-start-sub-main-to-empty'
import { chainingSubCamMicFromGetServerSubCamMicToStartSubEpic$ } from './@chaining/sub-cam-mic/1-from-get-server-sub-cam-mic-to-start-sub'
import { changeLangEpic$ } from './intl/epic'
import { commChannelMessagesToServerBcasterCamDisableEpic$ } from './comm-channel/messages/to-server/bcaster/cam/disable/epic'
import { commChannelMessagesToServerBcasterCamEnableEpic$ } from './comm-channel/messages/to-server/bcaster/cam/enable/epic'
import { commChannelMessagesToServerBcasterCamMicStopEpic$ } from './comm-channel/messages/to-server/bcaster/cam-mic-stop/epic'
import { commChannelMessagesToServerBcasterMicDisableEpic$ } from './comm-channel/messages/to-server/bcaster/mic/disable/epic'
import { commChannelMessagesToServerBcasterMicEnableEpic$ } from './comm-channel/messages/to-server/bcaster/mic/enable/epic'
import { countdownBcastDurationStartEpic$ } from './cowntdown/bcast-duration/start/epic'
import { countdownBcastDurationStopEpic$ } from './cowntdown/bcast-duration/stop/epic'
import { countdownFreePremiumDaysEpic$ } from './cowntdown/free-premium-days/start/epic'
import { countdownMidnightBcastTimeResetEpic$ } from './cowntdown/midnight-bcast-time-reset/start/epic'
import { errorsToModalsGrantPermissionsToGetStreamsEpic$ } from './errors-to-modals/grant-permissions-to-get-streams/epic'
import { extensionMapToAppChangeSourceEpic$ } from './extension-map-to-app/change-source/epic'
import { extensionMapToAppStopBcastEpic$ } from './extension-map-to-app/stop-bcast/epic'
import { extensionUserDataEpic$ } from './extension/user-data/epic'
import { livePaintDrawCommandsClearEpic$ } from './live-paint/draw/commands/clear/epic'
import { livePaintDrawCommandsRedoEpic$ } from './live-paint/draw/commands/redo/epic'
import { livePaintDrawCommandsUndoEpic$ } from './live-paint/draw/commands/undo/epic'
import { livePaintDrawStartEpic$ } from './live-paint/draw/start/epic'
import { livePaintDrawStopEpic$ } from './live-paint/draw/stop/epic'
import { livePaintDrawUpdateViewEpic$ } from './live-paint/draw/update-view/epic'
import { livePaintReplaceTrackOnDrawStartEpic$ } from './live-paint/replace-track/on-draw-start/epic'
import { livePaintReplaceTrackOnDrawStopEpic$ } from './live-paint/replace-track/on-draw-stop/epic'
import { livePaintUpdatePropsEpic$ } from './live-paint/draw/update-props/epic'
import { onPredefMsgConfirmCloseNoViewers$ } from './comm-channel/on-predef-msg-confirm-close-no-viewers/epic'
import { onPredefMsgConfirmCloseNowByNoViewers$ } from './comm-channel/on-predef-msg-close-now/no-viewers/epic'
import { onPubAvailableToSendCommMessageEpic$ } from './comm-channel/on-pub-available-send-cam-mic-stream-start/epic'
import { paintBcastStreamDrawCommandsClearEpic$ } from './paint-bcast-stream/draw/commands/clear/epic'
import { paintBcastStreamDrawCommandsRedoEpic$ } from './paint-bcast-stream/draw/commands/redo/epic'
import { paintBcastStreamDrawCommandsUndoEpic$ } from './paint-bcast-stream/draw/commands/undo/epic'
import { paintBCastStreamDrawStartEpic$ } from './paint-bcast-stream/draw/start/epic'
import { paintBCastStreamDrawStopEpic$ } from './paint-bcast-stream/draw/stop/epic'
import { paintBCastStreamDrawUpdatePropsEpic$ } from './paint-bcast-stream/draw/update-props/epic'
import { paintBCastStreamDrawUpdateViewEpic$ } from './paint-bcast-stream/draw/update-view/epic'
import { resyncGoogleEpic$ } from './resync/google/epic'
import { sendFeedbackEndBcastEpic$ } from './feedback/end-bcast/epic'
import { shareCamMicStreamGetStreamEpic$ } from './share-cam-mic-stream/get-stream/epic'
import { shareMainStreamGetStreamByExtensionEpic$ } from './share-main-stream/get-stream-by-extension/epic'
import { shareMainStreamGetStreamByExtensionToSwitchEpic$ } from './share-main-stream/get-stream-by-extension-to-switch/epic'
import { shareMainStreamGetStreamEpic$ } from './share-main-stream/get-stream/epic'
import { shareMainStreamGetStreamToSwitchEpic$ } from './share-main-stream/get-stream-to-switch/epic'
import { toastErrorsEpic$ } from './toast/errors/epic'
import { toastSuccessEpic$ } from './toast/success/epic'

import { deprecatedShareMainStreamGetStreamToSwitchEpic$ } from './share-main-stream/get-stream-to-switch/deprecated/epic'
import { deprecatedShareMainStreamGetStreamByExtensionToSwitchEpic$ } from './share-main-stream/get-stream-by-extension-to-switch/deprecated/epic'

import { deprecatedLivePaintReplaceTrackOnDrawStartEpic$ } from './live-paint/replace-track/on-draw-start/deprecated/epic'
import { deprecatedLivePaintReplaceTrackOnDrawStopEpic$ } from './live-paint/replace-track/on-draw-stop/deprecated/epic'

import { deprecatedChainingPubMainFromGetServerPubMainToStartPub$ } from './@chaining/pub-main/deprecated/3-from-get-server-pub-main-to-start-pub'
import { deprecatedChainingPubMainFromGetStreamByExtensionToCreateStreamContextId$ } from './@chaining/pub-main/deprecated/1-from-get-stream-by-extension-to-create-stream-context-id'
import { deprecatedChainingPubMainFromStartPubMainToCommChannel$ } from './@chaining/pub-main/deprecated/4-from-start-pub-main-to-comm-channel'
import { deprecatedChainingPubMainFromGetStreamToGetStreamContextId$ } from './@chaining/pub-main/deprecated/1-from-get-stream-to-create-stream-context-id'
import { deprecatedChainingPubMainFromCreateStreamContextIdToGetServerPubMain$ } from './@chaining/pub-main/deprecated/2-from-create-stream-context-id-to-get-server-pub-main'

import { deprecatedChainingSubMainFromGetServerSubMainToGetStreamContextEpic$ } from './@chaining/sub-main/deprecated/1-from-get-server-sub-main-to-get-stream-context'
import { deprecatedChainingSubMainFromGetStreamContextToStartSubEpic$ } from './@chaining/sub-main/deprecated/2-from-get-stream-context-to-start-sub'
import { chainingEventsFromBcasterDisconnectedToExit$ } from './@chaining/sub-main/events/from-participant-disconnected-to-exit'
import { apiBcastRoomVersionEpic$ } from './api-bcast/room/version/epic'
import { apiAiTtsCreateEpic$ } from './api-ai/tts/create/epic'
import { apiAiOcrCreateEpic$ } from './api-ai/ocr/create/epic'
import { apiAiTranslationCreateEpic$ } from './api-ai/translation/create/epic'
import { apiAiDescriptionCreateEpic$ } from './api-ai/description/create/epic'
import { aiTimerStart$ } from './ai-timer/start/epic'
import { aiTimerStop$ } from './ai-timer/stop/epic'
import { apiAiChainingFromOcrToTransOrTtsEpic$ } from './api-ai/@chaining/from-ocr-to-trans-or-tts/epic'
import { apiAiChainingFromTransToTtsEpic$ } from './api-ai/@chaining/from-trans-to-tts/epic'
import { bcastV2CommChannelMessagesFromServerEpic$ } from './bcast-v2/messages/from-server/epic'
import { bcastV2CommChannelMessagesFromServerOnCloseNowNoViewersEpic$ } from './bcast-v2/messages/from-server/on-close-now-no-viewers/epic'
import { bcastV2CommChannelMessagesFromServerOnConfirmCloseNoViewersEpic$ } from './bcast-v2/messages/from-server/on-confirm-close-no-viewers/epic'
import { chainingPubMainFromGetStreamByExtensionToRoomForPub$ } from './@chaining/pub-main/1-from-get-stream-by-extension-room-for-pub'

// ~~~~~~

export default combineEpics(
  // Libs

  // - Bcast

  ...ServiceBcast.React.Epics,

  bcastV2CommChannelMessagesFromServerEpic$ as any,

  bcastV2CommChannelMessagesFromServerOnCloseNowNoViewersEpic$ as any,

  bcastV2CommChannelMessagesFromServerOnConfirmCloseNoViewersEpic$ as any,

  // -- Pub

  apiBcastRoomForPubEpic$ as any,

  chainingPubMainFromGetStreamByExtensionToRoomForPub$ as any,
  chainingFromGetStreamToRoomForPub$ as any,
  chainingfromRoomForPubToConnect$ as any,

  chainingEventsFromRoomPubErrorToExitAndCancel$ as any,

  // -- Sub

  apiBcastRoomForSubEpic$ as any,

  chainingFromRoomForPubToStartViewer$ as any,

  chainingEventsFromRoomSubConnectedToExitAndCancel$ as any,
  chainingEventsFromBcasterDisconnectedToExit$ as any,

  // Root

  apiBcastRoomVersionEpic$ as any,

  // Intl

  changeLangEpic$ as any,

  // Toast

  toastErrorsEpic$ as any,
  toastSuccessEpic$ as any,

  // Bcast Api

  // - Current user

  apiBcastCurrentUserShowEpic$ as any,

  // - User settings

  apiBcastUserSettingsShowEpic$ as any,
  apiBcastUserSettingsUpdateEpic$ as any,
  apiBcastUserSettingsDeleteEpic$ as any,

  // - Subscription

  apiBcastSubscriptionShowEpic$ as any,

  // - Providers

  apiBcastProvidersLinkGoogleEpic$ as any,
  apiBcastProvidersUnlinkGoogleEpic$ as any,

  // - Stream context

  apiBcastStreamContextCreateEpic$ as any,
  apiBcastStreamContextShowEpic$ as any,

  // - Google some error => reload data

  resyncGoogleEpic$ as any,

  // - Google Class Room

  apiBcastGoogleClassRoomListEpic$ as any,
  apiBcastGoogleClassRoomAnnounceBcastLinkEpic$ as any,
  apiBcastGoogleClassRoomAnnounceCaptureEpic$ as any,

  // - Google Slides

  apiBcastGoogleSlidesListPresentationsEpic$ as any,
  apiBcastGoogleSlidesCreatePresentationEpic$ as any,
  apiBcastGoogleSlidesAddImageSlideEpic$ as any,
  apiBcastGoogleSlidesCreateAndAddImageToSlideEpic$ as any,

  // Store Api

  // - Products

  apiStoreProductsListEpic$ as any,

  // - Checkout

  apiStoreCheckoutCancelEpic$ as any,
  apiStoreCheckoutSendEmailEpic$ as any,
  apiStoreCheckoutValidateEmailCodeEpic$ as any,
  apiStoreCheckoutCreateSessionEpic$ as any,

  // - Subscriptions

  apiStoreSubscritionsCreateEpic$ as any,
  apiStoreSubscriptionCheckPaymentMethodEpic$ as any,
  apiStoreSubscriptionGetTimeEpic$ as any,
  apiStoreSubscriptionGetTimeOnGetScreenEpic$ as any,
  apiStoreSubscritionsUpgradePlanEpic$ as any,

  // Stream Manager Api

  // - Server

  apiStreamManagerServerPubMainGetEpic$ as any,
  apiStreamManagerServerPubCamGetEpic$ as any,

  apiStreamManagerServerSubMainGetEpic$ as any,
  apiStreamManagerServerSubCamMicGetEpic$ as any,

  // Broadcast

  bcastExitEpic$ as any,

  // - Communication channel

  bcastCommChannelInitEpic$ as any,
  bcastCommChannelMessagesFromServerEpic$ as any,
  onPubAvailableToSendCommMessageEpic$ as any,
  onPredefMsgConfirmCloseNoViewers$ as any,
  onPredefMsgConfirmCloseNowByNoViewers$ as any,

  // -- bcaster cam-mic

  commChannelMessagesToServerBcasterCamMicStopEpic$ as any,

  // -- bcaster cam

  commChannelMessagesToServerBcasterCamDisableEpic$ as any,
  commChannelMessagesToServerBcasterCamEnableEpic$ as any,

  // -- bcaster mic

  commChannelMessagesToServerBcasterMicDisableEpic$ as any,
  commChannelMessagesToServerBcasterMicEnableEpic$ as any,

  // - Pub/Sub main

  bcastStartSubMainEpic$ as any,
  bcastStartPubMainEpic$ as any,

  // - Pub/Sub cam-mic

  bcastStartPubCamMicEpic$ as any,
  bcastStartSubCamMicEpic$ as any,

  // - Share main stream

  shareMainStreamGetStreamEpic$ as any,
  shareMainStreamGetStreamByExtensionEpic$ as any,

  deprecatedShareMainStreamGetStreamToSwitchEpic$ as any,
  shareMainStreamGetStreamToSwitchEpic$ as any,

  deprecatedShareMainStreamGetStreamByExtensionToSwitchEpic$ as any,
  shareMainStreamGetStreamByExtensionToSwitchEpic$ as any,

  // - Share cam-mic stream

  shareCamMicStreamGetStreamEpic$ as any,

  // - Cancel

  cancelNowAllEpic$ as any,
  cancelNowCamMicEpic$ as any,

  // Chaining

  // - Chaining Pub main

  // -- 1

  deprecatedChainingPubMainFromGetStreamToGetStreamContextId$ as any,
  deprecatedChainingPubMainFromGetStreamByExtensionToCreateStreamContextId$ as any,

  // -- 2

  deprecatedChainingPubMainFromCreateStreamContextIdToGetServerPubMain$ as any,

  // -- 3

  deprecatedChainingPubMainFromGetServerPubMainToStartPub$ as any,

  // -- 4

  deprecatedChainingPubMainFromStartPubMainToCommChannel$ as any,

  // - Chaining Sub main

  // -- 1

  deprecatedChainingSubMainFromGetServerSubMainToGetStreamContextEpic$ as any,

  // -- 2

  deprecatedChainingSubMainFromGetStreamContextToStartSubEpic$ as any,

  // - Chaining Pub cam-mic

  // -- 1

  chainingPubCamMicFromShareCamMicStreamGetStreamToGetServerPubCamMicEpic$ as any,

  // -- 2

  chainingPubCamMicFromGetServerCamMicPubToStartPubEpic$ as any,

  // - Chaining Sub cam-mic

  // -- 1

  chainingSubCamMicFromGetServerSubCamMicToStartSubEpic$ as any,

  // - Chaining Store Duration

  chainingStoreDurationFromStartPubMain$ as any,
  chainingStoreDurationFromStartSubMain$ as any,

  // Live Paint: Draw

  livePaintDrawStartEpic$ as any,
  livePaintDrawStopEpic$ as any,
  livePaintUpdatePropsEpic$ as any,
  livePaintDrawUpdateViewEpic$ as any,
  livePaintDrawCommandsClearEpic$ as any,
  livePaintDrawCommandsRedoEpic$ as any,
  livePaintDrawCommandsUndoEpic$ as any,

  deprecatedLivePaintReplaceTrackOnDrawStartEpic$ as any,
  livePaintReplaceTrackOnDrawStartEpic$ as any,

  deprecatedLivePaintReplaceTrackOnDrawStopEpic$ as any,
  livePaintReplaceTrackOnDrawStopEpic$ as any,

  // Stream Paint: Draw

  paintBCastStreamDrawStartEpic$ as any,
  paintBCastStreamDrawStopEpic$ as any,
  paintBCastStreamDrawUpdatePropsEpic$ as any,
  paintBCastStreamDrawUpdateViewEpic$ as any,
  paintBcastStreamDrawCommandsClearEpic$ as any,
  paintBcastStreamDrawCommandsRedoEpic$ as any,
  paintBcastStreamDrawCommandsUndoEpic$ as any,

  // Errors to modals

  errorsToModalsGrantPermissionsToGetStreamsEpic$ as any,

  // Extension

  extensionUserDataEpic$ as any,

  // Extension commumication

  extensionMapToAppStopBcastEpic$ as any,
  extensionMapToAppChangeSourceEpic$ as any,

  // Free premium days count down

  countdownFreePremiumDaysEpic$ as any,

  // UTC Midnight countdown

  countdownMidnightBcastTimeResetEpic$ as any,

  // Bcast duration countdown

  countdownBcastDurationStartEpic$ as any,
  countdownBcastDurationStopEpic$ as any,

  // Feedback

  sendFeedbackEndBcastEpic$ as any,

  // IA

  // - Apis

  apiAiDescriptionCreateEpic$ as any,
  apiAiOcrCreateEpic$ as any,
  apiAiTranslationCreateEpic$ as any,
  apiAiTtsCreateEpic$ as any,

  // - Api chaining

  apiAiChainingFromOcrToTransOrTtsEpic$ as any,
  apiAiChainingFromTransToTtsEpic$ as any,

  // - Timer

  aiTimerStart$ as any,
  aiTimerStop$ as any,
)
