import { filter, map, mergeMap, Observable, takeUntil } from 'rxjs'
import { ApiAiAC, ApiAiAT } from '../../../../actions/api-ai/actions'
import { ofType, StateObservable } from 'redux-observable'
import { StoreState } from '../../../../../models/app/model'
import { ApiUtils } from '../../../utils'
import { EpicApiIaTtsCreateMC } from './mutators'
import { apiAiTtsCreate$ } from '../../../../../services/api-ai/text-to-speech/create'
import { cancelAll$$ } from '../../../../../subjects/cancel-all/subject'
import { Config } from '../../../../../config'

type Action = ReturnType<typeof ApiAiAC.ttsCreate>

export const apiAiTtsCreateEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(ApiAiAT.TTS_CREATE),

    filter(() => Config.Features.AI && state$.value.aiConfig.tts),

    mergeMap(({ payload }) =>
      apiAiTtsCreate$(payload.sentence.text, payload.opts).pipe(
        takeUntil(cancelAll$$),

        map((res) => ({
          res,
          idAiReq: payload.idAiReq,
          sentenceId: payload.sentence.id,
          sentenceText: payload.sentence.text,
        })),
      ),
    ),

    map((data) => {
      return ApiUtils.isSuccess(data.res)
        ? EpicApiIaTtsCreateMC.ok(
            data.idAiReq,
            data.sentenceId,
            data.sentenceText,
            data.res.response,
          )
        : EpicApiIaTtsCreateMC.error(data.res.response)
    }),
  )
