import React from 'react'
import { LayoutTopContentContext } from '../context/component'

// ~~~~~~ Hook

export const useLayoutTopContentContext = () => {
  const context = React.useContext(LayoutTopContentContext)

  if (!context) {
    throw new Error(
      `Layout top-content components cannot be rendered outside the LayoutTopContent component`,
    )
  }

  return context
}
