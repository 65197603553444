import { FormattedMessage } from 'react-intl'

// ~~~~~~ Types

type Props = {
  id: IntlMsgId
  values?: {
    [key: string]: string | number
  }
  children?(nodes: any): React.ReactElement | null
}

// ~~~~~~ Component

export const Trans: React.FC<Props> = ({ id, values, children }) => {
  if (!id) {
    return <div>----</div>
  }

  return (
    <FormattedMessage id={id} values={values}>
      {children}
    </FormattedMessage>
  )
}
