import { ofType, StateObservable } from 'redux-observable'
import { map, Observable } from 'rxjs'
import { StoreState } from '../../../../../../models/app/model'
import { BroadcastAC, BroadcastAT } from '../../../../../actions/bcast/actions'
import { ModalsMC } from '../../../../../actions-mutators/modals/mutators'
import { ModalsIds } from '../../../../../../constants/modals'

type Action = ReturnType<typeof BroadcastAC.confirmCloseNoViewers>

export const bcastV2CommChannelMessagesFromServerOnConfirmCloseNoViewersEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(BroadcastAT.CONFIRM_CLOSE_NO_VIEWERS),

    map(({ payload }) => {
      return ModalsMC.open(
        ModalsIds.ConfirmPredefMsgWillCloseBecauseNoViewers,
        /*opts*/ undefined,
        {
          value: payload.values.mins,
        },
      )
    }),
  )
