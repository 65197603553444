import { ofType, StateObservable } from 'redux-observable'
import { mergeMap, Observable, of } from 'rxjs'
import { StoreState } from '../../../../../../models/app/model'
import { BroadcastAC, BroadcastAT } from '../../../../../actions/bcast/actions'
import { CancelNowAC, CancelReasons } from '../../../../../actions/cancel-now/actions'

type Action = ReturnType<typeof BroadcastAC.closeNowNoViewers>

export const bcastV2CommChannelMessagesFromServerOnCloseNowNoViewersEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(BroadcastAT.CLOSE_NOW_NO_VIEWERS),

    mergeMap(() => {
      return of(
        BroadcastAC.exit('inactivity'),

        CancelNowAC.cancelAll([CancelReasons.Inactivity]),
      )
    }),
  )
