import { StateObservable, ofType } from 'redux-observable'
import { Observable, filter, mergeMap } from 'rxjs'
import { Config } from '../../../../../config'
import { StoreState } from '../../../../../models/app/model'
import {
  EpicApiIaTranslationCreateMC,
  EpicApiIaTranslationCreateMT,
} from '../../translation/create/mutators'
import { ApiAiAC } from '../../../../actions/api-ai/actions'

type Action = ReturnType<typeof EpicApiIaTranslationCreateMC.ok>

export const apiAiChainingFromTransToTtsEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(EpicApiIaTranslationCreateMT.OK),

    filter(() => Config.Features.AI && state$.value.aiConfig.ocr === 'enabled-with-translation'),

    mergeMap(({ payload }) => {
      //

      const obs$ = new Observable((observer) => {
        payload.data
          .map((data) => data.translation)
          .forEach((trans) => {
            observer.next(
              ApiAiAC.ttsCreate(payload.idAiReq, {
                id: trans.id,
                boundingBox: trans.boundingBox,
                text: trans.trans,
              }),
            )
          })

        observer.complete()
      })

      return obs$
    }),
  )
