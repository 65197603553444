import { guardIsCloseNowNoViewser } from './is-predef-close-now-no-viewers/guard'
import { guardIsConfirmCloseNoViewers } from './is-predef-confirm-close-no-viewers/guard'
import { guardIsPredefMessage } from './is-predef-message/guard'

// ~~~~~~

export const Guards = {
  isPredefMessage: guardIsPredefMessage,

  isCloseNowNoViewers: guardIsCloseNowNoViewser,

  isConfirmCloseNoViewers: guardIsConfirmCloseNoViewers,
} as const
