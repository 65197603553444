const prefix = 'ai-timer'

// ~~~~~~ Action Types

export const AiTimerAT = {
  START: `${prefix}-start`,

  STOP: `${prefix}-stop`,
} as const

// ~~~~~~ Action Creators

export const AiTimerAC = {
  start: (intervalDuration = 3_000) => ({
    type: AiTimerAT.START,
    payload: {
      intervalDuration,
    },
  }),

  stop: () => ({
    type: AiTimerAT.STOP,
  }),
} as const
