import { UtilsPaintFramework } from '@dn/utils'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { StoreState } from '../../../../../../models/app/model'
import { UtilsPics } from '../../../../../../utils/pics'
import { useDispatch } from 'react-redux'
import { AiMC } from '../../../../../../store/actions-mutators/ai/mutators'

// ~~~~~~ Hook

let url = ''

export const useJoinedBehaviourLogicContentMainAiInfo = () => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  // ~~~~~~ State

  // - Local

  const [, setUpdate] = useState(0)

  const [playing, setPlaying] = useState<{ playId: string }>({
    playId: 'init',
  })

  const [descriptionIsOpen, setDescriptionIsOpen] = useState(true)

  // - Store

  const { video, zoom, originalVideoW, originalVideoH } = useSelector(
    (state: StoreState) => state.paintBcastStreamVideo,
  )

  const ocr = useSelector((state: StoreState) => state.aiOcr)

  const translations = useSelector((state: StoreState) => state.aiTranslation)

  const tts = useSelector((state: StoreState) => state.aiTts)

  const description = useSelector((state: StoreState) => state.aiDescription)

  // - Refs

  const selfRef = useRef<HTMLDivElement>(null)

  const contentRef = useRef<HTMLDivElement>(null)

  const audioRef = useRef<HTMLAudioElement>(null)

  // ~~~~~~ Special

  if (tts.length === 0 && url) {
    try {
      URL.revokeObjectURL(url)
    } catch (err) {}
  }

  // ~~~~~~ Computed

  const lines = translations.length
    ? translations.flatMap((trans) =>
        trans.data.map((data) => ({
          ...data.translation,
          text: data.translation.trans,
        })),
      )
    : ocr.flatMap((item) => item.data.lines)

  const self = selfRef.current

  const relW = self ? self.clientWidth : 0
  const relH = self ? self.clientHeight : 0

  const finalLines = lines.map((line) => ({
    id: line.id,
    text: line.text,
    box: UtilsPics.calcBoundingBox(line.boundingBox, originalVideoW, originalVideoH, relW, relH),
    tts: tts.find((value) => value.sentenceId === line.id)?.audio,
  }))

  const scale = zoom / 100

  const descriptionText = description.captions
    .map((caption) => caption.text.trim())
    .filter(Boolean)
    .join('. ')

  const finalDescription = [
    descriptionText.charAt(0).toLocaleUpperCase(),
    descriptionText.slice(1),
  ].join('')

  // ~~~~~~ Handlers

  function onClickToggleDescription() {
    setDescriptionIsOpen(!descriptionIsOpen)
  }

  // ~~~~~~ Callbacks

  const setCanvasesWrapperSize = useCallback(() => {
    const self = selfRef.current
    const canvasesWrapper = contentRef.current

    if (!video || !self || !canvasesWrapper) return

    const relW = self.clientWidth
    const relH = self.clientHeight

    const { width, height, top, left } = UtilsPaintFramework.calcRelativeSizeAndTranslate(
      {
        oW: originalVideoW,
        oH: originalVideoH,
        relW,
        relH,
        scale,
      },
      {
        video,
        framework: self,
      },
    )

    canvasesWrapper.style.minWidth = `${width}px`
    canvasesWrapper.style.minHeight = `${height}px`

    canvasesWrapper.style.top = `${top}px`
    canvasesWrapper.style.left = `${left}px`

    // dispatch(PaintBCastStreamDrawAC.updateView(width, height))

    //
  }, [originalVideoH, originalVideoW, scale, video])

  // ~~~~~~ Effects

  // - On mount or callback change (original video dimensions change) set canvases wrapper w/h

  useEffect(() => {
    setCanvasesWrapperSize()

    //
  }, [setCanvasesWrapperSize])

  // - Observe self resize to update canvases wrapper
  //   this happens on resize the window

  useEffect(() => {
    const self = selfRef.current

    if (!self) return

    const resizeObserve = new ResizeObserver(() => {
      requestAnimationFrame(() => {
        setCanvasesWrapperSize()
      })
    })

    resizeObserve.observe(self)

    return () => {
      resizeObserve.disconnect()
    }
  }, [setCanvasesWrapperSize])

  // - Play sound

  useEffect(() => {
    const audio = audioRef.current

    if (!audio || !finalLines.length) return

    const playId = playing.playId

    if (playId !== 'init' && !playId.startsWith('next-')) return

    const idx = playId === 'init' ? 0 : Number(playId.slice(5)[0])

    const line = finalLines[idx]

    if (!line || !line.tts) return

    url = URL.createObjectURL(line.tts)

    audio.src = url

    setPlaying({ playId: line.id })

    audio.play().then(() => {
      audio.onended = () => {
        URL.revokeObjectURL(url)

        dispatch(AiMC.removeAudio(line.id))

        setPlaying({ playId: `next-${idx + 1}` })
      }
    })

    //
  }, [dispatch, finalLines, playing, tts])

  // - On umount clear blob url

  useEffect(() => {
    return () => {
      try {
        URL.revokeObjectURL(url)
      } catch (err) {}
    }
  }, [])

  // - Force render if no relative size

  useEffect(() => {
    if (relW && relH) return

    setUpdate(performance.now())

    //
  }, [relH, relW])

  // ~~~~~~

  return {
    selfRef,

    contentRef,

    audioRef,

    playing,

    description: finalDescription,

    descriptionIsOpen,

    finalLines,

    onClickToggleDescription,
  } as const
}
