import { StateObservable, ofType } from 'redux-observable'
import { Observable, filter, mergeMap, of } from 'rxjs'
import { Config } from '../../../../../config'
import { StoreState } from '../../../../../models/app/model'
import { EpicApiIaOcrCreateMC, EpicApiIaOcrCreateMT } from '../../ocr/create/mutators'
import { ApiAiAC } from '../../../../actions/api-ai/actions'

type Action = ReturnType<typeof EpicApiIaOcrCreateMC.ok>

export const apiAiChainingFromOcrToTransOrTtsEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(EpicApiIaOcrCreateMT.OK),

    filter(() => Config.Features.AI),

    mergeMap(({ payload }) => {
      const sentences: DN.Models.Ai.Sentence[] = payload.data.lines

      // OCR to Translation

      if (state$.value.aiConfig.ocr === 'enabled-with-translation') {
        return of(ApiAiAC.translationCreate(payload.idAiReq, sentences))
      }

      // OCR to TTS

      const obs$ = new Observable((observer) => {
        sentences.forEach((sentence) => {
          observer.next(ApiAiAC.ttsCreate(payload.idAiReq, sentence))
        })

        observer.complete()
      })

      return obs$
    }),
  )
