// Basic status error (!res.status checks also undefined)

const isErrorResponse = (res: StatusOnly) => !res.status || res.status >= 400

// Guard

const isSuccess = <T extends StatusOnly>(
  res: T | DNApiError<any>,
  isErrorFun?: (res: StatusOnly) => boolean,
): res is T => res.status !== 0 && (isErrorFun ? !isErrorFun(res) : !isErrorResponse(res))

const isUnauthorizedError = (res: StatusOnly) => res.status === 401

// ~~~~~~

export const ApiUtils = {
  isErrorResponse,
  isSuccess,
  isUnauthorizedError,
}
