import { CommChannelCreateData } from '../../../services/comm-channel/create/comm-channel-partial-service'

const prefix = 'at-bcast'

// ~~~~~ Action types

export const BroadcastAT = {
  // Pub

  START_PUB_MAIN: `${prefix}-start-pub-main`,
  START_PUB_CAM_MIC: `${prefix}-start-pub-cam-mic`,

  // Sub

  START_SUB_MAIN: `${prefix}-start-sub-main`,
  START_SUB_CAM_MIC: `${prefix}-start-sub-cam-mic`,

  // Streams

  CHANGE_SHARED_MAIN_STREAM: `${prefix}-change-shared-main-stream`,

  // Communication channel

  COMM_CHANNEL_INIT: `${prefix}-comm-channel-init`,

  // Bcast domain messages

  CONFIRM_CLOSE_NO_VIEWERS: `${prefix}-confirm-close-no-viwers`,

  CLOSE_NOW_NO_VIEWERS: `${prefix}-close-now-no-viewers`,

  // Exit

  EXIT: `${prefix}-exit`,
} as const

// ~~~~~~ Action Creators

export const BroadcastAC = {
  // Pub

  startPubMain: (
    pubConfig: DN.Services.Broadcast.PubConfig,
    stream: MediaStream,
    userPassToken: DN.Services.Broadcast.UserPassToken,
    iceServers: RTCIceServer[],
  ) => ({
    type: BroadcastAT.START_PUB_MAIN,
    payload: {
      pubConfig,
      stream,
      userPassToken,
      iceServers,
    },
  }),

  startPubCamMic: (
    pubConfig: DN.Services.Broadcast.PubConfig,
    stream: MediaStream,
    userPassToken: DN.Services.Broadcast.UserPassToken,
    iceServers: RTCIceServer[],
  ) => ({
    type: BroadcastAT.START_PUB_CAM_MIC,
    payload: {
      pubConfig,
      stream,
      userPassToken,
      iceServers,
    },
  }),

  // Sub

  startSubMain: (subConfig: DN.Services.Broadcast.SubConfig, iceServers: RTCIceServer[]) => ({
    type: BroadcastAT.START_SUB_MAIN,
    payload: {
      subConfig,
      iceServers,
    },
  }),

  startSubCamMic: (subConfig: DN.Services.Broadcast.SubConfig, iceServers: RTCIceServer[]) => ({
    type: BroadcastAT.START_SUB_CAM_MIC,
    payload: {
      subConfig,
      iceServers,
    },
  }),

  // Streams

  changeSharedMainStream: () => ({
    type: BroadcastAT.CHANGE_SHARED_MAIN_STREAM,
  }),

  // Communications

  commChannelInit: (channelData: CommChannelCreateData) => ({
    type: BroadcastAT.COMM_CHANNEL_INIT,
    payload: {
      channelData,
    },
  }),

  // Bcast domain messages

  confirmCloseNoViewers: (
    payload: DN.Services.BcastDomain.Messages.PrefedMessage.ConfirmCloseNoViewers['payload'],
  ) => ({
    type: BroadcastAT.CONFIRM_CLOSE_NO_VIEWERS,
    payload,
  }),

  closeNowNoViewers: () => ({
    type: BroadcastAT.CLOSE_NOW_NO_VIEWERS,
  }),

  // Exit

  exit: (reason: DN.Models.Exit.ExitReason) => ({
    type: BroadcastAT.EXIT,
    payload: {
      reason,
    },
  }),
} as const
