export const guardIsMessage = (
  message: unknown,
): message is DN.Services.BcastDomain.Messages.ModelBase => {
  if (message instanceof Blob) return false

  if (typeof message !== 'object' || !message) return false

  if (!('type' in message) || !message.type) return false

  if (!('payload' in message) || typeof message.payload !== 'object' || !message.payload) {
    return false
  }

  return true
}
