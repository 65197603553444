import { UtilsStore } from '@dn/utils'
import { AppInitState } from '../../../../models/app/model'
import {
  EpicApiIaOcrCreateMC,
  EpicApiIaOcrCreateMT,
} from '../../../epics/api-ai/ocr/create/mutators'
import { AiFieldsMR } from '../../../../models/ai/model'
import { AiTimerAT } from '../../../actions/ai-timer/actions'

const initState = AppInitState.aiOcr

type State = typeof initState

const reducer: any = {}

// ####################################################################################################
// ~~~~~~ Create api OK
// ####################################################################################################

reducer[EpicApiIaOcrCreateMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof EpicApiIaOcrCreateMC.ok>,
): State => {
  return [
    {
      counter: payload.idAiReq.counter,
      id: payload.idAiReq.id,
      data: payload.data,
    },
  ]
}

// ####################################################################################################
// ~~~~~~ Config changed
// ####################################################################################################

reducer[AiFieldsMR.__model__.MT.SET_MODEL] = (
  state: State,
  { payload }: ReturnType<typeof AiFieldsMR.__model__.MC.setModel>,
): State => {
  return !payload.ocr || payload.ocr !== 'disabled' ? state : initState
}

// ####################################################################################################
// ~~~~~~ Timer STOP
// ####################################################################################################

reducer[AiTimerAT.STOP] = (state: State): State => {
  return initState
}

// ~~~~~~

export const aiOcrReducer = UtilsStore.dynReducer(initState, reducer)
