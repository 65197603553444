import { FormattedMessage } from 'react-intl'

// ~~~~~~ Types

type Props = {
  id: IntlMsgId
  template: string
  values?: {
    [key: string]: any
  }
  children?(...nodes: any): React.ReactElement | null
}

// ~~~~~~ Component

export const DangerTrans: React.FC<Props> = ({ id, template, values, children }) => {
  if (!id) {
    return <div>----</div>
  }

  return (
    <FormattedMessage id={id} defaultMessage={template} values={values}>
      {children}
    </FormattedMessage>
  )
}
