import { Guards } from './guards'
import { commChannelInit$ } from './init/comm-channel-partial-service'
import { SendMessage } from './send-message'
import { commChannelStop } from './stop/comm-channel-partial-service'

export const CommChannelService = {
  init$: commChannelInit$,

  stop: commChannelStop,

  SendMessage,

  Guards,
} as const
