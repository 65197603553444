import React, { useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import { fromEvent, filter } from 'rxjs'
import { ConstEventsElement } from '@dn/constants'

// ~~~~~~ Constants

const DOMElementName = 'modal-root'

// ~~~~~~ Types

type Props = {
  children: React.ReactNode

  modalElementId: string
  onModalPortalClickOut: (evt?: any) => void
}

// ~~~~~~ Component

export const ModalRootPortal: React.FC<Props> = ({
  modalElementId,
  onModalPortalClickOut,

  children,
}) => {
  // Get the DOM node of the portal
  // "as HTMLElement" because we know that the node exists in html.index and it's not null
  const DOMElement = document.getElementById(DOMElementName) as HTMLElement

  // Create DIV node wih with the id of the modal and store as Ref
  // to prevent mount/dismount as constant append/remove element to/from dom
  const div = useRef(document.createElement('div')).current

  div.setAttribute('id', modalElementId)

  // ~~~~~~ Effects

  // Listen mousedown to propagate click-out
  useEffect(() => {
    const sub = fromEvent(div, ConstEventsElement.MouseDown)
      .pipe(filter((evt) => evt.target === div.children[0]))
      .subscribe({
        next: (evt) => onModalPortalClickOut(evt),
      })

    return () => {
      sub.unsubscribe()
    }
  }, [div, onModalPortalClickOut])

  // Add/Remove element to/from this portal
  useEffect(() => {
    DOMElement.appendChild(div)

    return () => {
      DOMElement.removeChild(div)
    }
  }, [DOMElement, div])

  // ~~~~~~ Portal

  return ReactDOM.createPortal(children, div)
}
