import { UtilsStore } from '@dn/utils'
import { AppInitState } from '../../../../models/app/model'
import { AiFieldsMR } from '../../../../models/ai/model'
import { AiTimerAT } from '../../../actions/ai-timer/actions'

const initState = AppInitState.aiConfig

type State = typeof initState

const reducer: any = {
  ...AiFieldsMR.__model__.Reducer,
}

// ####################################################################################################
// ~~~~~~ Timer START
// ####################################################################################################

reducer[AiTimerAT.START] = (state: State): State => {
  return {
    ...state,
    uiStatus: 'running',
  }
}

// ####################################################################################################
// ~~~~~~ Timer STOP
// ####################################################################################################

reducer[AiTimerAT.STOP] = (state: State): State => {
  return initState
}

// ~~~~~~

export const aiConfigReducer = UtilsStore.dynReducer(initState, reducer)
