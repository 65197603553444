import { filter, map, mergeMap, Observable, takeUntil } from 'rxjs'
import { ApiAiAC, ApiAiAT } from '../../../../actions/api-ai/actions'
import { ofType, StateObservable } from 'redux-observable'
import { StoreState } from '../../../../../models/app/model'
import { ApiUtils } from '../../../utils'
import { EpicApiIaTranslationCreateMC } from './mutators'
import { apiAiTranslateCreate$ } from '../../../../../services/api-ai/translation/create'
import { cancelAll$$ } from '../../../../../subjects/cancel-all/subject'
import { Config } from '../../../../../config'

type Action = ReturnType<typeof ApiAiAC.translationCreate>

export const apiAiTranslationCreateEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(ApiAiAT.TRANSLATION_CREATE),

    filter(() => Config.Features.AI && state$.value.aiConfig.ocr === 'enabled-with-translation'),

    mergeMap(({ payload }) =>
      apiAiTranslateCreate$(payload.sentences, payload.lang).pipe(
        takeUntil(cancelAll$$),

        map((res) => ({
          res,
          idAiReq: payload.idAiReq,
        })),
      ),
    ),

    map((data) => {
      return ApiUtils.isSuccess(data.res)
        ? EpicApiIaTranslationCreateMC.ok(data.idAiReq, data.res.response)
        : EpicApiIaTranslationCreateMC.error(data.res.response)
    }),
  )
