import { guardIsMessage } from '../is-message/guard'

const type: DN.Services.BcastDomain.Messages.PrefedMessage.Model['type'] = 'predef-msg'

export const guardIsPredefMessage = (
  message: unknown,
): message is DN.Services.BcastDomain.Messages.PrefedMessage.Model => {
  if (!guardIsMessage(message)) return false

  if (typeof message !== 'object' || !message) return false

  if (!('type' in message) || !message.type || message.type !== type) return false

  if (!('payload' in message) || typeof message.payload !== 'object' || !message.payload) {
    return false
  }

  return true
}
