const prefix = 'mt-bcast-comm-channel-messages-from-server-epic'

// ~~~~~~ Mutators Types

export const EpicBcastCommChannelMessagesFromServerMT = {
  BCAST_CURRENT_STATE: `${prefix}-bcast-current-state`,

  BCASTER_CAM_MIC_STOP: `${prefix}-bcast-cam-mic-stop`,

  BCASTER_ENABLED_CAM: `${prefix}-bcaster-enabled-cam`,
  BCASTER_DISABLED_CAM: `${prefix}-bcaster-disabled-cam`,

  BCASTER_ENABLED_MIC: `${prefix}-bcaster-enabled-mic`,
  BCASTER_DISABLED_MIC: `${prefix}-bcaster-disabled-mic`,

  PRESENCE_RESUME: `${prefix}-presence-resume`,

  // Predefined messages

  PREDEF_MSG_CONFIRM_CLOSE_NO_VIEWERS: `${prefix}-predef-msg-confirm-close-no-viewers`,

  // Close now
  PREDEF_MSG_CLOSE_NOW_NO_VIEWERS: `${prefix}-predef-msg-close-now-no-viewers`,
} as const

// ~~~~~~ Mutators Types

export const EpicBcastCommChannelMessagesFromServerMC = {
  bcastCurrentState: (data: {
    camIsMuted: boolean
    micIsMuted: boolean
    sharingCamMic: boolean
  }) => ({
    type: EpicBcastCommChannelMessagesFromServerMT.BCAST_CURRENT_STATE,
    payload: data,
  }),

  bcasterCamMicStop: () => ({
    type: EpicBcastCommChannelMessagesFromServerMT.BCASTER_CAM_MIC_STOP,
  }),

  bcasterEnabledCam: () => ({
    type: EpicBcastCommChannelMessagesFromServerMT.BCASTER_ENABLED_CAM,
  }),

  bcasterDisabledCam: () => ({
    type: EpicBcastCommChannelMessagesFromServerMT.BCASTER_DISABLED_CAM,
  }),

  bcasterEnabledMic: () => ({
    type: EpicBcastCommChannelMessagesFromServerMT.BCASTER_ENABLED_MIC,
  }),

  bcasterDisabledMic: () => ({
    type: EpicBcastCommChannelMessagesFromServerMT.BCASTER_DISABLED_MIC,
  }),

  presenceResume: (
    data: DN.Services.CommChannel.Messages.FromServer.WithoutAck.Info.Presence.Req['payload'],
  ) => ({
    type: EpicBcastCommChannelMessagesFromServerMT.PRESENCE_RESUME,
    payload: data,
  }),

  predefMsgConfirmCloseNoViewers: (
    data: DN.Services.CommChannel.Messages.FromServer.WithoutAck.Info.PrefedMsg.ConfirmCloseNoViewers.Req['payload'],
  ) => ({
    type: EpicBcastCommChannelMessagesFromServerMT.PREDEF_MSG_CONFIRM_CLOSE_NO_VIEWERS,
    payload: data,
  }),

  predefMsgCloseNowNoViewers: () => ({
    type: EpicBcastCommChannelMessagesFromServerMT.PREDEF_MSG_CLOSE_NOW_NO_VIEWERS,
  }),
} as const
