const prefix = 'mt-api-ia-translation-create'

// ~~~~~~ Mutators Types

export const EpicApiIaTranslationCreateMT = {
  OK: `${prefix}-ok`,
  ERROR: `${prefix}-error`,
} as const

// ~~~~~~ Mutators Creators

export const EpicApiIaTranslationCreateMC = {
  ok: (idAiReq: DN.Models.Ai.ReqId, data: DN.Models.Ai.Translation.Api.Create.ResParsed) => ({
    type: EpicApiIaTranslationCreateMT.OK,
    payload: {
      idAiReq,
      data,
    },
  }),

  error: (errors: Infos[]) => ({
    type: EpicApiIaTranslationCreateMT.ERROR,
    payload: {
      errors,
    },
  }),
} as const
