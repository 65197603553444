import { BcastConnectEvents } from '@dn/bcast/dist/types/typing/partial-service'
import { StateObservable, ofType } from 'redux-observable'
import { EMPTY, Observable, filter, mergeMap, of } from 'rxjs'
import { StoreState } from '../../../../../models/app/model'
import { Config } from '../../../../../config'
import { ServiceBcast } from '@dn/bcast'
import { BroadcastAC } from '../../../../actions/bcast/actions'
import { CancelNowAC, CancelReasons } from '../../../../actions/cancel-now/actions'
import { ConstBcast } from '../../../../../constants/bcast'

type Action = Extract<BcastConnectEvents, { type: '@dn/Bcast-conn-connected' }>

export const chainingEventsFromRoomSubConnectedToExitAndCancel$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType('@dn/Bcast-conn-connected'),

    filter(() => Config.Features.BcastService2 && !state$.value.dnBcast.iAmBcaster),

    mergeMap(({ payload }) => {
      const room = state$.value.dnBcast.room

      if (!ServiceBcast.Guards.isRoom(room)) return EMPTY

      const bcaster = payload.room.remoteParticipants.values().some((participant) => {
        if (!participant.metadata) return false

        try {
          const meta = JSON.parse(participant.metadata)

          if (meta.kind === ConstBcast.Participant.Bcaster) return true

          //
        } catch (err) {}

        return false
      })

      if (bcaster) return EMPTY

      return of(
        BroadcastAC.exit('session-end'),

        CancelNowAC.cancelAll([CancelReasons.SessionEnded]),
      )
    }),
  )
