const prefix = 'mt-api-ia-ocr-create'

// ~~~~~~ Mutators Types

export const EpicApiIaOcrCreateMT = {
  OK: `${prefix}-ok`,
  ERROR: `${prefix}-error`,
} as const

// ~~~~~~ Mutators Creators

export const EpicApiIaOcrCreateMC = {
  ok: (idAiReq: DN.Models.Ai.ReqId, data: DN.Models.Ai.Ocr.Api.Create.ResParsed) => ({
    type: EpicApiIaOcrCreateMT.OK,
    payload: {
      idAiReq,
      data,
    },
  }),

  error: (errors: Infos[]) => ({
    type: EpicApiIaOcrCreateMT.ERROR,
    payload: {
      errors,
    },
  }),
} as const
