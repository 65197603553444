import { ConstHttpVerbs } from '@dn/constants'

// ~~~~~~ [P]

const getDescriptionPath = (path: string) =>
  `https://sulfatoqueestoybebiendosulfato.cognitiveservices.azure.com${path}`

const getOcrPath = (path: string) =>
  `https://sulfatoqueestoybebiendosulfato.cognitiveservices.azure.com${path}`

const getTranslationPath = (path: string) => `https://api.cognitive.microsofttranslator.com${path}`

const getTtsPath = (path: string) => `https://westeurope.tts.speech.microsoft.com${path}`

// ~~~~~~ Image description

const Description = {
  Create: {
    Method: ConstHttpVerbs.POST,
    Path: '/vision/v3.2/describe',
    getUrl: () => getDescriptionPath(Description.Create.Path),
  },
} as const

// ~~~~~~ Optical character recognition

const Ocr = {
  Create: {
    Method: ConstHttpVerbs.POST,
    Path: '/vision/v3.2/ocr',
    getUrl: () => getOcrPath(Ocr.Create.Path),
  },
} as const

// ~~~~~~ Translate

const Translate = {
  Create: {
    Method: ConstHttpVerbs.POST,
    Path: '/translate?api-version=3.0&to=:lang',
    getUrl: (lang: string = 'en') =>
      getTranslationPath(Translate.Create.Path.replace(':lang', lang)),
  },
} as const

// ~~~~~~ Text to speech

const Tts = {
  Create: {
    Method: ConstHttpVerbs.POST,
    Path: '/cognitiveservices/v1',
    getUrl: () => getTtsPath(Tts.Create.Path),
  },
} as const

// ~~~~~~

export const AiRoutes = {
  Description,

  Ocr,

  Translate,

  Tts,
} as const
