import { StateObservable, ofType } from 'redux-observable'
import { Observable, filter, mergeMap, of } from 'rxjs'
import { StoreState } from '../../../../../models/app/model'
import { BcastConnectEvents } from '@dn/bcast/dist/types/typing/partial-service'
import { BroadcastAC } from '../../../../actions/bcast/actions'
import { CancelNowAC, CancelReasons } from '../../../../actions/cancel-now/actions'
import { Config } from '../../../../../config'

type Action = Extract<BcastConnectEvents, { type: '@dn/Bcast-conn-error' }>

export const chainingEventsFromRoomPubErrorToExitAndCancel$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType('@dn/Bcast-conn-error'),

    filter(() => Config.Features.BcastService2),

    mergeMap(() =>
      of(BroadcastAC.exit('publish-fail'), CancelNowAC.cancelAll([CancelReasons.PublishFail])),
    ),
  )
