import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { DevicePIPEnabled } from '../../../../../../../constants/misc'
import { UiPipFullscreenFieldsMR } from '../../../../../../../models/bcast/ui-pip-fullscreen/model'
import { UtilsFullscreen } from '@dn/utils'
import { JoinedDesktopContentMainToolbarsMainButtonsCapturePaint } from './buttons/capture-paint/component'
import { JoinedDesktopContentMainToolbarsMainButtonsCaptureShare } from './buttons/capture-share/component'
import { JoinedDesktopMainToolbarsMainButtonsFullscreen } from './buttons/fullscreen/component'
import { JoinedDesktopContentMainToolbarsMainButtonsPIP } from './buttons/pip/component'
import { JoinedDesktopContentMainToolbarsMainButtonsZoom } from './buttons/zoom/component'
import { ClassName, STJoinedDesktopContentMainToolbarsMain } from './style'
import { Config } from '../../../../../../../config'
import { JoinedDesktopContentMainToolbarsMainButtonsAi } from './buttons/ai/component'

// ~~~~~~ Types

type Props = {
  video: HTMLVideoElement | null
}

// ~~~~~~ Component

export const JoinedDesktopContentMainToolbarsMain: React.FC<Props> = ({ video }) => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  // ~~~~~~ State

  const [, setUpdate] = useState(performance.now())

  // ~~~~~~ Callbacks

  // We need this in order to sync pip and fullscreen icons
  // we can do it better using the store instead

  const onUpdate = useCallback(() => {
    setUpdate(performance.now())

    dispatch(UiPipFullscreenFieldsMR.updatedPn.MC.change(performance.now()))

    //
  }, [dispatch])

  // ~~~~~~ Render

  return (
    <STJoinedDesktopContentMainToolbarsMain>
      {/* Capture Share */}

      <div className={`${ClassName}--icon-btn`}>
        <JoinedDesktopContentMainToolbarsMainButtonsCaptureShare video={video} />
      </div>

      {/* Capture Paint */}

      <div className={`${ClassName}--icon-btn`}>
        <JoinedDesktopContentMainToolbarsMainButtonsCapturePaint video={video} />
      </div>

      {/* Zoom */}

      <div className={`${ClassName}--zoom`}>
        <JoinedDesktopContentMainToolbarsMainButtonsZoom />
      </div>

      {/* PIP */}

      {DevicePIPEnabled ? (
        <div className={`${ClassName}--icon-btn`}>
          <JoinedDesktopContentMainToolbarsMainButtonsPIP video={video} onUpdate={onUpdate} />
        </div>
      ) : undefined}

      {/* Full screen */}

      {UtilsFullscreen.fullscreenIsSupported ? (
        <div className={`${ClassName}--icon-btn`}>
          <JoinedDesktopMainToolbarsMainButtonsFullscreen onUpdate={onUpdate} />
        </div>
      ) : undefined}

      {/* IA */}

      {Config.Features.AI ? (
        <div className={`${ClassName}--icon-btn`}>
          <JoinedDesktopContentMainToolbarsMainButtonsAi video={video} />
        </div>
      ) : undefined}

      {/* -- */}
    </STJoinedDesktopContentMainToolbarsMain>
  )
}
