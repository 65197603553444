// ####################################################################################################
// ~~~~~~ getThemeValue: Extract a value from the theme applying a function or getting it as key
// ####################################################################################################

type STProps = {
  $forceTheme?: Theme
  $hasErrors?: boolean
  theme?: {
    mode?: Theme
  }
}

export const getThemeValue = (props: any, values: any) => {
  const finalTheme = props.forceTheme ? props.forceTheme : props.theme && props.theme.mode

  const themeValue = values[finalTheme]

  return typeof themeValue === 'function' ? themeValue(props) : themeValue
}

// ####################################################################################################
// ~~~~~~ applyTheme:
// ####################################################################################################

export const applyTheme = (prop: ThemeColor, props: STProps) =>
  props.$forceTheme ? prop[props.$forceTheme] : prop[props.theme?.mode || 'light']

// ####################################################################################################
// ~~~~~~ theme: Used in styled components as theme(ColorTheme) return in execution the theme color
// ####################################################################################################

export const theme = (values: any) => (props: STProps) => getThemeValue(props, values)
