import { useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { StoreState } from '../../../../../../../../../models/app/model'
import { ModalsIds } from '../../../../../../../../../constants/modals'
import { ModalsMC } from '../../../../../../../../../store/actions-mutators/modals/mutators'
import { AiTimerAC } from '../../../../../../../../../store/actions/ai-timer/actions'

// ~~~~~~ Hook

export const useJoinedBehaviourLogicContentMainToolbarsMainButtonsAi = (
  video: HTMLVideoElement | null,
) => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  // ~~~~~~ State

  // - Local

  const [, setUpdate] = useState(0)

  // - Store

  const isRunning = useSelector((state: StoreState) => state.aiConfig.uiStatus === 'running')

  // - Refs

  const tooltipRef = useRef<HTMLSpanElement>(null)

  // ~~~~~~ Computed

  const tooltipTop = tooltipRef.current ? -tooltipRef.current.clientHeight - 8 : -34

  // ~~~~~~ Handlers

  function onClickIa() {
    if (isRunning) {
      dispatch(AiTimerAC.stop())

      return
    }

    dispatch(ModalsMC.open(ModalsIds.AiConfig))
  }

  // ~~~~~~ Effects

  // - Force update on mount to calculate tooltip position

  useEffect(() => {
    setUpdate(performance.now())
  }, [])

  // ~~~~~~

  return {
    tooltipRef,
    tooltipTop,

    isRunning,

    onClickIa,
  } as const
}
