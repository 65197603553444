import { EMPTY, filter, mergeMap, Observable, of, takeUntil, timer } from 'rxjs'
import { AiTimerAC, AiTimerAT } from '../../../actions/ai-timer/actions'
import { ofType, StateObservable } from 'redux-observable'
import { StoreState } from '../../../../models/app/model'
import { Config } from '../../../../config'
import { UtilsPics } from '../../../../utils/pics'
import { aiTimerStop$$ } from '../subject/subject'
import { cancelAll$$ } from '../../../../subjects/cancel-all/subject'
import { ApiAiAC } from '../../../actions/api-ai/actions'

type Action = ReturnType<typeof AiTimerAC.start>

let reqCounter: bigint | undefined = undefined

try {
  reqCounter = BigInt(0)
} catch (err) {}

export const aiTimerStart$ = (action$: Observable<Action>, state$: StateObservable<StoreState>) =>
  action$.pipe(
    ofType(AiTimerAT.START),

    filter(() => Config.Features.AI && reqCounter !== undefined),

    // filter(() => state$.value.aiConfig.uiStatus === 'init'),

    mergeMap(({ payload }) => {
      //

      return timer(0, payload.intervalDuration).pipe(
        //

        takeUntil(cancelAll$$),

        takeUntil(aiTimerStop$$),

        mergeMap(() => {
          if (reqCounter === undefined) return EMPTY

          const videoData = state$.value.paintBcastStreamVideo

          if (!videoData.video) return EMPTY

          reqCounter = reqCounter + 1n

          return UtilsPics.takeScreenshotAsBlob$({
            video: videoData.video,
            originalVideoW: videoData.originalVideoW,
            originalVideoH: videoData.originalVideoH,
          }).pipe(takeUntil(cancelAll$$), takeUntil(aiTimerStop$$))
        }),
      )
    }),

    mergeMap((blob) => {
      if (!reqCounter) return EMPTY

      const idAiReq: DN.Models.Ai.ReqId = {
        counter: reqCounter,
        id: crypto.randomUUID(),
      }

      return of(ApiAiAC.ocrCreate(idAiReq, blob), ApiAiAC.descriptionCreate(idAiReq, blob))
    }),
  )
