import { EMPTY, filter, mergeMap, Observable, tap } from 'rxjs'
import { AiTimerAC, AiTimerAT } from '../../../actions/ai-timer/actions'
import { ofType, StateObservable } from 'redux-observable'
import { StoreState } from '../../../../models/app/model'
import { Config } from '../../../../config'
import { aiTimerStop$$ } from '../subject/subject'

type Action = ReturnType<typeof AiTimerAC.stop>

export const aiTimerStop$ = (action$: Observable<Action>, state$: StateObservable<StoreState>) =>
  action$.pipe(
    ofType(AiTimerAT.STOP),

    filter(() => Config.Features.AI),

    tap(() => aiTimerStop$$.next()),

    mergeMap(() => EMPTY),
  )
