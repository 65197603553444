const prefix = 'mt-api-ia-description-create'

// ~~~~~~ Mutators Types

export const EpicApiIaDescriptionCreateMT = {
  OK: `${prefix}-ok`,
  ERROR: `${prefix}-error`,
} as const

// ~~~~~~ Mutators Creators

export const EpicApiIaDescriptionCreateMC = {
  ok: (idAiReq: DN.Models.Ai.ReqId, data: DN.Models.Ai.Description.Api.Create.ResParsed) => ({
    type: EpicApiIaDescriptionCreateMT.OK,
    payload: {
      idAiReq,
      data,
    },
  }),

  error: (errors: Infos[]) => ({
    type: EpicApiIaDescriptionCreateMT.ERROR,
    payload: {
      errors,
    },
  }),
} as const
