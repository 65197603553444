import { ofType, StateObservable } from 'redux-observable'
import { filter, map, Observable } from 'rxjs'
import { Config } from '../../../../../config'
import { StoreState } from '../../../../../models/app/model'
import { BroadcastAC } from '../../../../actions/bcast/actions'
import {
  EpicBcastStartPubMainMC,
  EpicBcastStartPubMainMT,
} from '../../../bcast/start-pub-main/mutators'

type Action = ReturnType<typeof EpicBcastStartPubMainMC.ok>

export const deprecatedChainingPubMainFromStartPubMainToCommChannel$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(EpicBcastStartPubMainMT.OK),

    filter(() => !Config.Features.BcastService2),

    map(() => {
      const serverPubMain = state$.value.streamManagerServerPubMain

      return BroadcastAC.commChannelInit({
        env: Config.Api.Bcast.WS.ENV,
        meetId: serverPubMain.broadcastId,
        kind: 'bcaster',
        token: state$.value.subscription.token,
      })
    }),
  )
