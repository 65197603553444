const genDescriptionHeaders = (headers?: AppHeaders): { headers: { [key: string]: string } } => ({
  headers: {
    ...headers,
    'Ocp-Apim-Subscription-Key': '4c789d7e98b64159b369c490ba9b2c1b',
    'Content-Type': 'application/octet-stream',
  },
})

const genOcrHeaders = (headers?: AppHeaders): { headers: { [key: string]: string } } => ({
  headers: {
    ...headers,
    'Ocp-Apim-Subscription-Key': '4c789d7e98b64159b369c490ba9b2c1b',
    'Content-Type': 'application/octet-stream',
  },
})

const genTranslateHeaders = (headers?: AppHeaders): { headers: { [key: string]: string } } => ({
  headers: {
    ...headers,
    'Ocp-Apim-Subscription-Key': '67ce412058254cfc8be166a287c6fa58',
    'Ocp-Apim-Subscription-Region': 'westeurope',
    'Content-Type': 'application/json',
  },
})

const genTtsHeaders = (headers?: AppHeaders): { headers: { [key: string]: string } } => ({
  headers: {
    ...headers,
    'Ocp-Apim-Subscription-Key': '9c8ccb6465fd4146893929d11e6967dc',
    'Content-Type': 'application/ssml+xml',
    'X-Microsoft-OutputFormat': 'audio-16khz-32kbitrate-mono-mp3',
    'Ocp-Apim-Subscription-Region': 'westeurope',
  },
})

// ~~~~~~

export const AiHeaderUtils = {
  genDescriptionHeaders,

  genOcrHeaders,

  genTranslateHeaders,

  genTtsHeaders,
} as const
