import { UtilsPaintFramework } from '@dn/utils'
import { EMPTY, Observable } from 'rxjs'

type TakeScreenshotData = {
  video: HTMLVideoElement

  originalVideoH: number

  originalVideoW: number

  imageType?: 'image/png' | 'image/jpeg' | 'image/webp'

  quality?: number // 0 - 1 for types that support lossy compression
}

function takeScreenshotAsBlob$({
  video,
  originalVideoH,
  originalVideoW,
  imageType,
  quality,
}: TakeScreenshotData) {
  const canvas = document.createElement('canvas')

  canvas.width = originalVideoW
  canvas.height = originalVideoH

  const context2d = canvas.getContext('2d')

  if (!context2d) return EMPTY

  context2d.imageSmoothingEnabled = false

  context2d.drawImage(video, 0, 0, originalVideoW, originalVideoH)

  const obs$ = new Observable<Blob>((observer) => {
    const blobCallback = (blob: Blob | null) => {
      if (!blob) {
        observer.complete()
        return
      }

      observer.next(blob)
      observer.complete()
    }

    canvas.toBlob(blobCallback, imageType, quality)
  })

  return obs$
}

interface BoundingBox {
  x: number
  y: number
  w: number
  h: number
}

const calcBoundingBox = (
  originalBoxStr: string,
  oW: number,
  oH: number,
  relW: number,
  relH: number,
): BoundingBox => {
  const [fW, fH] = UtilsPaintFramework.calcRelativeSize({ oW, oH, relW, relH, scale: 1 })

  const [x, y, w, h] = originalBoxStr.split(',').map(Number)

  const scaleW = fW / oW

  const scaleH = fH / oH

  const newBox: BoundingBox = {
    x: x * scaleW,
    y: y * scaleH,
    w: w * scaleW,
    h: h * scaleH,
  }

  return newBox
}

// ~~~~~~

export const UtilsPics = {
  takeScreenshotAsBlob$,

  calcBoundingBox,
} as const
