import { ReactEnvs } from '@dn/react-envs'
import { LocalStorageDebugExtension } from './services/storage/local/debug-extension'
import { UtilsHeaders } from '@dn/utils'

const env = ReactEnvs.loadRuntimeEnv() as ENV

const Environment = {
  Local: 'local',
  Devel: 'devel',
  Alpha: 'alpha',
  Beta: 'beta',
  Prod: 'production',
} as const

export const NodeEnvironent = {
  DEV: 'development',
  PROD: 'production',
  TEST: 'test',
} as const

const ProjectName = env.REACT_APP_PROJECT_NAME || 'dn-broadcast'

const CurEnvironment = env.REACT_APP_ENVIRONMENT

const CurEnvironmentPipeline = env.REACT_APP_ENVIRONMENT_PIPELINE || 'no-env'

const BcastV2Env =
  env.REACT_APP_ANALYTICS_ENV === 'local' ? 'devel' : env.REACT_APP_ANALYTICS_ENV || 'devel'

const Features: string[] = (env.REACT_APP_FEATURES || '')
  .split(',')
  .map((feature) => feature.trim())

const Origin = global.window ? window.location.origin.replace(/\/$/, '') : ''

let AllBlocked = false

try {
  localStorage.getItem('test')
} catch (err) {
  AllBlocked = true
}

const Version = env.REACT_APP_VERSION || 'dev-5.x.y'

const Revision = env.REACT_APP_REVISION || 'no-revision'

const Tag = env.REACT_APP_TAG || 'no-tag'

const ExtensionId = CurEnvironment !== Environment.Prod ? LocalStorageDebugExtension.get() : ''

// ~~~~~~

export const Config = {
  // General

  AllBlocked,
  Origin,
  BcastUrl: env.REACT_APP_BCAST_URL || '',
  Environment: env.NODE_ENV || NodeEnvironent.PROD,
  isProduction: env.NODE_ENV === NodeEnvironent.PROD,
  ProjectName,

  LogoutUrl: Origin,
  LogoutNoLicenseUrl: `${Origin}/no-subscription`,

  isExtension: false,

  // App

  App: {
    Version,
    Revision,
    Tag,

    Environment: CurEnvironment,
    red5proUsernamePrefix: `webapp-${CurEnvironmentPipeline}`,
    Feedback: {
      BcastEndTimesToShowAgain: Number(env.REACT_APP_FEEDBACK_TIMES_TO_SHOW_AGAIN || -1),
    },
  },

  // Flavours

  Flavour: {
    Domain: env.REACT_APP_FLAVOUR_DOMAIN || '',
  },

  // Extension

  Extension: {
    Id: ExtensionId || env.REACT_APP_BCAST_EXTENSION_ID || 'no-extension-id',
  },

  // Enabled features

  Features: {
    Debug: Features.includes('DEBUG'),

    DebugAnalytics: Features.includes('DEBUG_ANALYTICS'),

    DebugEraser: Features.includes('DEBUG_ERASER'),

    //

    CanvasReplaceTrack: Features.includes('CANVAS_REPLACE_TRACK'),

    FeedbackBcastEnd: Features.includes('FEEDBACK_BCAST_END'),

    BcastService2: Features.includes('BCAST_SERVICE_2'),

    BcastCheckVersion: Features.includes('BCAST_CHECK_VERSION'),

    AI: Features.includes('AI'),
  },

  // Logs

  Logs: {
    GoogleGTM: Features.includes('DEBUG_GOOGLE_GTM'),
    Actions: Features.includes('DEBUG_ACTIONS'),
    FreePremiumDays: Features.includes('DEBUG_FREE_PREMIUM_DAYS'),
    BroadcastService: Features.includes('DEBUG_BCAST_SERVICE'),
    BroadcastEvents: Features.includes('DEBUG_EVENTS'),
    WebRTC: Features.includes('DEBUG_WEBRTC'),
    DrawService: Features.includes('DEBUG_DRAW_SERVICE'),
    DeviceSelectService: Features.includes('DEBUG_DEVICE_SELECT_SERVICE'),
    CountdownService: Features.includes('DEBUG_COUNTDOWN_SERVICE'),
    Bcast2Service: Features.includes('DEBUG_BCAST_2_SERVICE'),
  },

  // Bcast

  Bcast: {
    Protocol: 'wss://',
    Environment: BcastV2Env,
  },

  // Api

  Api: {
    Debugger: {
      URI: env.REACT_APP_DEBUG_ENDPOINT || `https://dn-issues.com/${ProjectName}`,
      Headers: {},
    },

    Bcast: {
      IdPrefix: env.REACT_APP_BCAST_FLAVOUR || '',

      LOCAL_URI: env.REACT_APP_LOCAL_URI || '',

      URI: env.REACT_APP_API_BCAST_URI || 'https://api-bcast-not-defined',

      Headers: UtilsHeaders.getSimpleHeaders(env.REACT_APP_API_BCAST_HEADER),

      Custom: {
        googleAccessToken: 'x-google-access-token',
      },

      WS: {
        ENV: CurEnvironmentPipeline,

        URI: env.REACT_APP_API_BCAST_WEBSOCKET_URI || 'wss://api-bcast-ws-not-defined',

        // Cannot be less as publish ack require at least 5 seconds after the server receives the message
        AckTimout: 6000,
      },
    },

    StreamManager: {
      URI: env.REACT_APP_API_STREAM_URI || 'stream-api-uri-not-defined',

      Headers: {},
    },

    Store: {
      URI: env.REACT_APP_API_STORE_URI || 'https://api-store-not-defined',

      Headers: UtilsHeaders.getSimpleHeaders(env.REACT_APP_API_STORE_HEADER),
    },

    StunTurn: {
      URI: env.REACT_APP_API_STUN_TURN_URI || 'https://api-stun-turn-not-defined',

      CredentialsHeaders: UtilsHeaders.getMultiHeaders(
        env.REACT_APP_API_STUN_TURN_CREDENTIALS_HEADERS,
      ),

      Key: env.REACT_APP_API_STUN_TURN_KEY || '',

      Timeout: Number(env.REACT_APP_API_STUN_TURN_TIMEOUT) || 5000,
      DefaultServers: (env.REACT_APP_API_STUN_TURN_DEFAULT_STUN || '').split(','),
    },
  },

  // Intl

  Intl: {
    InfosForInfer: { id: 'wildcard' } as Infos,

    DefaultLocale: (env.REACT_APP_DEFAULT_LOCALE as Lang) || ('en' as Lang),

    SupportedLocales: (env.REACT_APP_SUPPORTED_LOCALES?.split(',') as Lang[]) || (['en'] as Lang[]),
  },

  // Others

  // - Keycode to enable peer conn stats

  PCSCode: env.REACT_APP_PCSCODE || 'IDDQD', // Keycode to enable peer conn stats

  // - Links

  Links: {
    FindOutMore: env.REACT_APP_LINKS_FIND_OUT_MORE,
    SupportInfo: env.REACT_APP_LINKS_SUPPORT_INFO,

    ExtensionInfo: env.REACT_APP_LINKS_EXTENSION_INFO,
    LetUsKnow: env.REACT_APP_LINKS_LET_US_KNOW,

    StaticSite: env.REACT_APP_LINKS_STATIC_SITE,
    Feedback: env.REACT_APP_LINKS_SUPPORT,
    ManageAccount: env.REACT_APP_LINKS_MANAGE_ACCOUNT,
    MirrorEffect: env.REACT_APP_LINKS_MIRROR_EFFECT,

    HowEnableDevicesBrowserPermissions:
      env.REACT_APP_LINKS_HOW_TO_ENABLE_DEVICES_BROWSER_PERMISSIONS,

    BuyBroadcastURL: env.REACT_APP_LINKS_BUY_LICENSE_URL,
    UpgradeBroadcastURL: env.REACT_APP_LINKS_UPDATE_LICENSE_URL,
    GrantRecordPermissionsOSX: env.REACT_APP_GRANT_RECORD_PERMISSIONS_OSX_LINK,

    // ???
    ExtensionInfoLearnMore: env.REACT_APP_LINKS_EXTENSION_LEARN_MORE,
  },

  // Cam and Mic

  CamMic: {
    fps: 30,
    minSize: 134,

    micBandwidth: 520, // stereo: 2 x 260
  },

  // Third parties

  ThirdParty: {
    // - Sentry

    Sentry: {
      DSN: env.REACT_APP_SENTRY_DSN,
    },

    // - Analytics (Snowplow)

    Analytics: {
      env: env.REACT_APP_ANALYTICS_ENV || 'devel',
      URL: env.REACT_APP_ANALYTICS_URL || 'https://invalid-url',
      PostPath: env.REACT_APP_ANALYTICS_POST_PATH,
    },

    // - Auth0

    Auth0: {
      Domain: env.REACT_APP_AUTH0_DOMAIN || '',
      ClientId: env.REACT_APP_AUTH0_CLIENT_ID || '',
      Audience: env.REACT_APP_AUTH0_AUDIENCE || '',
      CallbackUrl: Origin,
      RefreshTokens: true,
      CacheLocation: 'localstorage', // memory | localstorage
    },

    // - Stripe

    Stripe: {
      PublishableKey: env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '',
    },

    // - Google

    Google: {
      GTM: {
        Id: env.REACT_APP_GOOGLE_TAG_MANAGER_ID || '',
        Env: env.REACT_APP_GOOGLE_TAG_MANAGER_ENV || '',
        Auth: env.REACT_APP_GOOGLE_TAG_MANAGER_AUTH || '',
      },

      Auth: {
        DevelToken: env.REACT_APP_GOOGLE_DEVEL_TOKEN || '',

        ClientId: env.REACT_APP_GOOGLE_AUTH_CLIENT_ID || '',

        // https://developers.google.com/identity/protocols/oauth2/scopes

        Scope: [
          // Classroom: List courses
          'https://www.googleapis.com/auth/classroom.courses.readonly',

          // Classroom: View & manage announcement
          'https://www.googleapis.com/auth/classroom.announcements',

          // Slides: View & manage presentations
          'https://www.googleapis.com/auth/presentations',

          // Drive: View and manage Google Drive files and folders that you have opened or created with this app
          'https://www.googleapis.com/auth/drive.file',
        ].join(' '),

        ScopeViewer: [
          // Slides: View & manage presentations
          'https://www.googleapis.com/auth/presentations',

          // Drive: View and manage Google Drive files and folders that you have opened or created with this app
          'https://www.googleapis.com/auth/drive.file',
        ].join(' '),
      },
    },
  },
} as const

// ~~~~~~

console.info(String.fromCodePoint(0x002728), Revision, `(${Version})`)
// console.log(Config)
