import { Trans } from '../../../../../../components/common/intl/trans'
import { LazySVG } from '../../../../../../components/svgs/lazy-svg/component'
import { useJoinedBehaviourLogicContentMainAiInfo } from '../../../../behaviour-logic/content/main/ai-info/hook'
import { ClassName, STJoinedDesktopContentMainAiInfo } from './style'

// ~~~~~~ Constants

const IconArrowBarLeft = LazySVG('icons/arrow-bar-left')

const IconArrowBarRight = LazySVG('icons/arrow-bar-right')

// ~~~~~~ Component

export const JoinedDesktopContentMainAiInfo = () => {
  // ~~~~~~ Hooks

  const logic = useJoinedBehaviourLogicContentMainAiInfo()

  // ~~~~~~ Render

  return (
    <STJoinedDesktopContentMainAiInfo ref={logic.selfRef}>
      <div ref={logic.contentRef} className={`${ClassName}--video`}>
        {/* Sound */}

        <audio ref={logic.audioRef} />

        {/* Texts */}

        <div className={`${ClassName}--video--frame`}>
          {logic.finalLines.map((line) => (
            <div
              style={{
                top: line.box.y,
                left: line.box.x - 8,
                // width: line.box.width,
                // height: line.box.height,
                fontSize: line.box.h,
              }}
              key={line.id}
              className={`${ClassName}--video--frame--line ${logic.playing.playId === line.id ? 'playing' : ''}`}
            >
              {line.text}
            </div>
          ))}

          {/* Description */}

          {logic.description && logic.descriptionIsOpen ? (
            <div className={`${ClassName}--video--frame--desc`}>
              {/* Title */}

              <div className={`${ClassName}--video--frame--desc--title`}>
                <Trans id="wildcard" values={{ value: 'Description' }} />
              </div>

              {/* Description */}

              <div className={`${ClassName}--video--frame--desc--text`}>{logic.description}</div>

              {/* Action colapse */}

              <div className={`${ClassName}--video--frame--desc--actions`}>
                <div
                  className={`${ClassName}--video--frame--desc--actions--action`}
                  onClick={logic.onClickToggleDescription}
                >
                  <IconArrowBarRight size={14} />
                </div>
              </div>
            </div>
          ) : undefined}

          {/*  Action open */}

          {logic.description && !logic.descriptionIsOpen ? (
            <div
              className={`${ClassName}--video--frame--action`}
              onClick={logic.onClickToggleDescription}
            >
              <IconArrowBarLeft size={14} />
            </div>
          ) : undefined}

          {/* - */}
        </div>
      </div>
    </STJoinedDesktopContentMainAiInfo>
  )
}
