import { filter, map, mergeMap, Observable, takeUntil } from 'rxjs'
import { ApiAiAC, ApiAiAT } from '../../../../actions/api-ai/actions'
import { ofType, StateObservable } from 'redux-observable'
import { StoreState } from '../../../../../models/app/model'
import { ApiUtils } from '../../../utils'
import { EpicApiIaDescriptionCreateMC } from './mutators'
import { apiAiDescriptionCreate$ } from '../../../../../services/api-ai/description/create'
import { cancelAll$$ } from '../../../../../subjects/cancel-all/subject'
import { Config } from '../../../../../config'

type Action = ReturnType<typeof ApiAiAC.descriptionCreate>

export const apiAiDescriptionCreateEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(ApiAiAT.DESCRIPTION_CREATE),

    filter(() => Config.Features.AI && state$.value.aiConfig.description),

    mergeMap(({ payload }) =>
      apiAiDescriptionCreate$(payload.imgBlob).pipe(
        takeUntil(cancelAll$$),

        map((res) => ({
          res,
          idAiReq: payload.idAiReq,
        })),
      ),
    ),

    map((data) => {
      return ApiUtils.isSuccess(data.res)
        ? EpicApiIaDescriptionCreateMC.ok(data.idAiReq, data.res.response)
        : EpicApiIaDescriptionCreateMC.error(data.res.response)
    }),
  )
