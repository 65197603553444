import styled from 'styled-components'
import { theme } from '../../../../../../../../../style/utils/theme'
import {
  STJoinedDesktopContentMainToolbarsButtonsCommonStyle,
  CommonStyleSvgColors,
} from '../common-style'
import { Colors } from '../../../../../../../../../style/theme/colors'

// ~~~~~~ Constants

export const ClassName = 'joined-desktop-content-main-toolbars-main-buttons-ai'

// ~~~~~~ Component

export const STJoinedDesktopContentMainToolbarsMainButtonsAi = styled.div`
  ${STJoinedDesktopContentMainToolbarsButtonsCommonStyle}

  svg.${ClassName}--icon {
    path {
      fill: ${theme(CommonStyleSvgColors.color)};
      stroke: none;
    }
  }

  svg.${ClassName}--icon.running {
    path {
      fill: ${theme(Colors.BRONX_50_50)};
    }
  }

  &.with-tooltip {
    .tooltiptext {
      width: unset;
      white-space: nowrap;
    }
  }
`
