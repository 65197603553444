export const ModalsIds = {
  ViewHasEnded: 'view-has-ended',
  YouCanBuyBroadcast: 'you-can-buy-broadcast',

  ExitEditingImage: 'exit-editing-image',

  CreateAnAccountFreeOrBuy: 'plans',
  Step1EmailPass: 'step1-email-pass',
  Step2VerificationCode: 'step-2verification-code',
  LoggedStep1AddPaymentMethod: 'logged-step-1-add-payment-method',
  LoggedStep2Buying: 'logged-step-2-buying',
  FreeAccountWithoutTime: 'free-account-without-time',

  SafariChangeSource: 'safari-change-source',

  ConfirmUserAlreadyExists: 'confirm-user-already-exists',
  ConfirmThanksForBuy: 'confirm-thanks-for-buy',

  ConfirmAllBlocked: 'confirm-all-blocked',
  ConfirmGrantSystemPermissions: 'confirm-grant-system-permissions',
  ConfirmUnblockGetScreen: 'confirm-unblock-get-screen',
  ConfirmStopBroadcasting: 'confirm-stop-broadcasting',
  ConfirmUserLimitReached: 'confirm-user-limit-reached',

  ConfirmBroadcasterDisconectedByFail: 'broadcaster-disconnected-by-fail',

  ConfirmViewerDisconnectedByOffline: 'confirm-viewer-disconnected-by-offline',
  ConfirmViewerDisconnectedByConnFail: 'confirm-viewer-disconnected-by-conn-fail',
  ConfirmViewerDisconnectedByWebsocketFail: 'confirm-viewer-disconnected-by-websocket-fail',
  ConfirmViewerDisconnectedByVideoLoad: 'confirm-viewer-disconnected-by-video-load',

  ConfirmGetCamMicError: 'confirm-get-cam-mic-error',
  ConfirmStopCamMicStream: 'confirm-stop-cam-mic-stream',

  ConfirmYourBrowswerIsTooOld: 'confirm-your-browser-is-too-old',

  ConfirmPredefMsgWillCloseBecauseNoViewers: 'confirm-predef-msg-will-close-becasuse-no-viewers',
  ConfirmPrefedMsgClosedByNoViewers: 'confirm-predef-msg-closed-by-no-viewers',

  ConfirmShouldCheckAllGooglePermissionsToLink:
    'confirm-should-check-all-google-permissions-to-link',

  ConfirmSignInTimeout: 'confirm-sign-in-timeout',

  GoogleSignIn: 'google-sign-in',

  LinkGoogleInBcast: 'link-google-in-bcast',

  EndBcastFeedback: 'end-bcast-feedback',

  AiConfig: 'ai-config',
} as const
