import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'

import { StoreState } from '../../models/app/model'
import { ApiBcastCurrentUserAC } from '../../store/actions/api-bcast/current-user/actions'
import { UtilsLog } from '../../utils/logs'
import { AuthMC } from '../../store/actions-mutators/auth/mutators'
import { useNavigate, useLocation } from 'react-router-dom'
import { ExtensionRoutes, Routes } from '../../constants/routes/routes'
import { Config } from '../../config'

// ~~~~~~ Component

export const useCheckAuth = () => {
  // ~~~~~~ Hooks

  const navigate = useNavigate()

  const path = useLocation().pathname

  const dispatch = useDispatch()

  const location = useLocation()

  // isAuthenticated: returns if we have the auth0 token

  const { getAccessTokenSilently, isLoading, isAuthenticated, logout } = useAuth0()

  // ~~~~~~ State

  const { isSessionChecked, errors } = useSelector((state: StoreState) => state.currentUser)

  const { redirectPath } = useSelector((state: StoreState) => state.root)

  const currentUser = useSelector((state: StoreState) => state.currentUser)

  // ~~~~~~ Effects

  // - Auto login

  useEffect(() => {
    if (currentUser.uiStatus !== 'init' || currentUser.token) return

    if (ExtensionRoutes.includes(path as any)) return

    if (Config.AllBlocked) return

    if (isLoading || isSessionChecked) return

    if (isAuthenticated) {
      UtilsLog.devLog('useCheckAuth', 'isAuthenticated')

      dispatch(AuthMC.getTokenStart())

      getAccessTokenSilently()
        .then((token) => {
          // devLog('Auth0 token', token)

          dispatch(ApiBcastCurrentUserAC.show(token))
        })
        .catch((err) => {
          UtilsLog.devWarn('Error getting token', err.message)

          dispatch(AuthMC.getTokenError())
        })

      return
    }

    UtilsLog.devLog('useCheckAuth', 'NOT', 'isAuthenticated')

    const inUserZone = !!~path.search(Routes.Started) || !!~path.search(Routes.UserSettings)

    dispatch(AuthMC.setSessionChecked())

    if (path === Routes.Main) {
      navigate(Routes.Join, { replace: true })
    }

    if (!inUserZone) return

    UtilsLog.devLog(
      String.fromCodePoint(128256),
      'useCheckAuth',
      'auto login',
      'navigate',
      redirectPath,
    )

    navigate(redirectPath, { replace: true })

    //
  }, [
    isSessionChecked,
    dispatch,
    getAccessTokenSilently,
    isLoading,
    isAuthenticated,
    path,
    navigate,
    redirectPath,
    currentUser.uiStatus,
    currentUser.token,
  ])

  // - Call to get current user fail

  useEffect(() => {
    if (!errors.length || path === Routes.Main) return

    UtilsLog.devLog(
      String.fromCodePoint(128256),
      'useCheckAuth',
      'get cur user fail navigate to',
      redirectPath,
    )

    navigate(redirectPath, { replace: true })

    //
  }, [errors, navigate, path, redirectPath])

  // - Use effect all blocked

  useEffect(() => {
    if (!Config.AllBlocked) return

    dispatch(AuthMC.setSessionChecked())

    if (path === Routes.Main) return

    UtilsLog.devLog(
      String.fromCodePoint(128256),
      'useCheckAuth',
      'all blocked',
      'navigate to',
      redirectPath,
    )

    navigate(redirectPath, { replace: true })

    //
  }, [dispatch, navigate, path, redirectPath])

  // - Logout from auth0 if DN Api cannot get the user info with this auth0 token

  useEffect(() => {
    if (!errors.length || !errors.some((err) => err.id === 'api.current-user.show.Error')) return

    // !Config.isExtension &&
    //   ExtensionService.SendMessage.ToExtenstion.loggedStateChanged('not-logged')

    UtilsLog.devLog('Logout: get current user API failed')

    const returnTo =
      location.pathname === Routes.Start || location.pathname === Routes.Join
        ? window.location.href
        : Config.LogoutUrl

    logout({
      logoutParams: {
        returnTo,
      },
    })

    //
  }, [errors, location.pathname, logout])
}
