import { StateObservable, ofType } from 'redux-observable'
import { Observable, map, mergeMap, tap } from 'rxjs'
import { StoreState } from '../../../../../models/app/model'
import { ApiBcastRoomAC, ApiBcastRoomAT } from '../../../../actions/api-bcast/room/actions'
import { EpicApiBcastRoomPubMC } from './mutators'
import { apiBcastRoomForPub$ } from '../../../../../services/api-bcast/room/for-pub'
import { ApiUtils } from '../../../utils'
import { ServiceWebRtc } from '@dn/webrtc'
import { UtilsBcast } from '../../../../../utils/bcast'

type Action = ReturnType<typeof ApiBcastRoomAC.forPub>

export const apiBcastRoomForPubEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(ApiBcastRoomAT.FOR_PUB),

    mergeMap(({ payload }) => {
      const token = state$.value.subscription.token

      // const fullBcastId = payload.bcastId // `${payload.bcastId}-bcast-vanilla`

      const bcastId = UtilsBcast.getPublicBcastId(payload.bcastId)

      if (bcastId !== payload.bcastId) {
        console.warn('pub', bcastId, 'vs', payload.bcastId)
      }

      return apiBcastRoomForPub$(token, bcastId)
    }),

    // On error, stop screen stream

    tap((res) => {
      if (ApiUtils.isSuccess(res)) return

      ServiceWebRtc.Media.Stream.stop$([state$.value.dnBcast.localScreenStream])
    }),

    map((res) =>
      ApiUtils.isSuccess(res)
        ? EpicApiBcastRoomPubMC.ok(res.response)
        : EpicApiBcastRoomPubMC.error(res.response),
    ),
  )
