import React from 'react'
import { useLayoutTopContentContext } from '../../../hooks/use-layout-top-content-context'

// ~~~~~~ Component

export const LayoutTopContentTopLeft: React.FC<any> = ({ children }) => {
  const { kind } = useLayoutTopContentContext()

  return <>{kind === 'top-left' ? children : null}</>
}
