import { Observable } from 'rxjs'
import { AiHeaderUtils } from '../utils/headers'
import { AiRoutes } from '../constants/routes'

const { Create } = AiRoutes.Tts

// ####################################################################################################
// ~~~~~~ Translate: From string in some lang to string in other lang
// ####################################################################################################

// type Res = DN.Models.Ai.Tts.Api.Create.Res
type ResParsed = DNApiResParsed<DN.Models.Ai.Tts.Api.Create.ResParsed>
type ResError = DNApiError<Infos[]>

export type TtsOpts = {
  voiceLang?: string
  gender?: 'Male' | 'Female'
  voiceName?: string
}

export const apiAiTtsCreate$ = (text: string, opts?: Partial<TtsOpts>, headers?: AppHeaders) => {
  //

  const finalVoiceLang = opts?.voiceLang || 'en-US'

  const finalGender = opts?.gender || 'Male'

  const finalVoiceName = opts?.voiceName || 'en-US-ChristopherNeural'

  const body: DN.Models.Ai.Tts.Api.Create.Req = `<speak version='1.0' xml:lang='en-US'><voice xml:lang='${finalVoiceLang}' xml:gender='${finalGender}' name='${finalVoiceName}'>${text}</voice></speak>`

  // Is there a crappier API than Promises? No, neither in this universe nor in any other

  const obs$ = new Observable<ResError | ResParsed>((observer) => {
    fetch(Create.getUrl(), {
      ...AiHeaderUtils.genTtsHeaders(headers),
      method: Create.Method,
      body,
    })
      .then((res) => {
        return new Promise<ResError | ResParsed>((resolve, reject) => {
          res
            .blob()
            .then((blob) =>
              resolve({
                status: res.status,
                response: blob,
              }),
            )
            .catch((err) =>
              reject({
                status: res.status,
                response: [{ id: 'wildcard', values: { value: `ERR IA TTS: ${err}` } }],
              }),
            )
        })
      })
      .catch((err) => {
        const res: ResError = {
          status: 0,
          response: [{ id: 'wildcard', values: { value: `ERR IA TTS: ${err}` } }],
        }

        return res
      })
      .then((res) => {
        observer.next(res)
        observer.complete()
      })
  })

  return obs$
}
