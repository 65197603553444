const prefix = 'mt-ai'

// ~~~~~~ Mutators Types

export const AiMT = {
  REMOVE_AUDIO: `${prefix}-remove-audio`,
} as const

// ~~~~~~ Mutators Creators

export const AiMC = {
  removeAudio: (id: string) => ({
    type: AiMT.REMOVE_AUDIO,
    payload: {
      id,
    },
  }),
} as const
