import { RemoteSVG } from '@dn/components'

import IconAi from '../../../imgs/svgs/icons/ai.svg'
import IconArrowBack from '../../../imgs/svgs/icons/arrow-back.svg'
import IconArrowBarLeft from '../../../imgs/svgs/icons/arrow-bar-left.svg'
import IconArrowBarRight from '../../../imgs/svgs/icons/arrow-bar-right.svg'
import IconArrowDown from '../../../imgs/svgs/icons/arrow-down.svg'
import IconBin from '../../../imgs/svgs/icons/bin.svg'
import IconBook from '../../../imgs/svgs/icons/book.svg'
import IconCam from '../../../imgs/svgs/icons/cam.svg'
import IconCamFeedback from '../../../imgs/svgs/icons/camera-feedback.svg'
import IconCapAppDark from '../../../imgs/svgs/icons/capture-app-dark.svg'
import IconCapAppLight from '../../../imgs/svgs/icons/capture-app-light.svg'
import IconCapDesktopDark from '../../../imgs/svgs/icons/capture-desktop-dark.svg'
import IconCapDesktopLight from '../../../imgs/svgs/icons/capture-desktop-light.svg'
import IconCapTabDark from '../../../imgs/svgs/icons/capture-tab-dark.svg'
import IconCapTabLight from '../../../imgs/svgs/icons/capture-tab-light.svg'
import IconChevronRight from '../../../imgs/svgs/icons/chevron-right.svg'
import IconCircleArrow from '../../../imgs/svgs/icons/circle-arrow.svg'
import IconCircleEmpty from '../../../imgs/svgs/icons/circle-empty.svg'
import IconCircleError from '../../../imgs/svgs/icons/circle-error.svg'
import IconCircleSuccess from '../../../imgs/svgs/icons/circle-success.svg'
import IconClose from '../../../imgs/svgs/icons/close.svg'
import IconColor from '../../../imgs/svgs/icons/color.svg'
import IconCompress from '../../../imgs/svgs/icons/compress.svg'
import IconDownload from '../../../imgs/svgs/icons/download.svg'
import IconEditPic from '../../../imgs/svgs/icons/edit-pic.svg'
import IconEraser from '../../../imgs/svgs/icons/eraser.svg'
import IconExit from '../../../imgs/svgs/icons/exit.svg'
import IconEyeNoView from '../../../imgs/svgs/icons/eye-no-view.svg'
import IconEyeView from '../../../imgs/svgs/icons/eye-view.svg'
import IconFaceBad from '../../../imgs/svgs/icons/face-bad.svg'
import IconFaceHappy from '../../../imgs/svgs/icons/face-happy.svg'
import IconFaceOk from '../../../imgs/svgs/icons/face-ok.svg'
import IconFile from '../../../imgs/svgs/icons/file.svg'
import IconFullscreen from '../../../imgs/svgs/icons/fullscreen.svg'
import IconGear from '../../../imgs/svgs/icons/gear.svg'
import IconGoogleBlue from '../../../imgs/svgs/icons/google-blue.svg'
import IconGoogleWhite from '../../../imgs/svgs/icons/google-white.svg'
import IconGrid from '../../../imgs/svgs/icons/grid.svg'
import IconHelp from '../../../imgs/svgs/icons/help.svg'
import IconInfo from '../../../imgs/svgs/icons/info.svg'
import IconKeyboard from '../../../imgs/svgs/icons/keyboard.svg'
import IconLayout from '../../../imgs/svgs/icons/layout.svg'
import IconLock from '../../../imgs/svgs/icons/lock.svg'
import IconMail from '../../../imgs/svgs/icons/mail.svg'
import IconMenu from '../../../imgs/svgs/icons/menu.svg'
import IconMeshWorld from '../../../imgs/svgs/icons/mesh-world.svg'
import IconPipActive from '../../../imgs/svgs/icons/pip-active.svg'
import IconPipInactive from '../../../imgs/svgs/icons/pip-inactive.svg'
import IconRedo from '../../../imgs/svgs/icons/redo.svg'
import IconRefresh from '../../../imgs/svgs/icons/refresh.svg'
import IconSettings from '../../../imgs/svgs/icons/settings.svg'
import IconSharePaint from '../../../imgs/svgs/icons/share-paint.svg'
import IconSingOutAlt from '../../../imgs/svgs/icons/sign-out-alt.svg'
import IconSpeakersOff from '../../../imgs/svgs/icons/speakers-off.svg'
import IconSpeakersOn from '../../../imgs/svgs/icons/speakers-on.svg'
import IconTick from '../../../imgs/svgs/icons/tick.svg'
import IconTimes from '../../../imgs/svgs/icons/times.svg'
import IconToolHighLighter from '../../../imgs/svgs/icons/tool-highlighter.svg'
import IconToolMarker from '../../../imgs/svgs/icons/tool-marker.svg'
import IconToolPen from '../../../imgs/svgs/icons/tool-pen.svg'
import IconUndo from '../../../imgs/svgs/icons/undo.svg'
import IconUser from '../../../imgs/svgs/icons/user.svg'
import IconUsersGroup from '../../../imgs/svgs/icons/users-group.svg'
import IconWarning from '../../../imgs/svgs/icons/warning.svg'
import IconZoomIn from '../../../imgs/svgs/icons/zoom-in.svg'
import IconZoomOut from '../../../imgs/svgs/icons/zoom-out.svg'

// Logos

import LogoBcastBrand from '../../../imgs/svgs/logos/broadcast-brand.svg'
import LogoBcastSquare from '../../../imgs/svgs/logos/broadcast-square.svg'
import LogoGoogle from '../../../imgs/svgs/logos/google.svg'

// ~~~~~~ Constants

const SvgFiles = {
  'icons/ai': IconAi,
  'icons/arrow-back': IconArrowBack,
  'icons/arrow-bar-left': IconArrowBarLeft,
  'icons/arrow-bar-right': IconArrowBarRight,
  'icons/arrow-down': IconArrowDown,
  'icons/bin': IconBin,
  'icons/book': IconBook,
  'icons/cam': IconCam,
  'icons/camera-feedback': IconCamFeedback,
  'icons/capture-app-dark': IconCapAppDark,
  'icons/capture-app-light': IconCapAppLight,
  'icons/capture-desktop-dark': IconCapDesktopDark,
  'icons/capture-desktop-light': IconCapDesktopLight,
  'icons/capture-tab-dark': IconCapTabDark,
  'icons/capture-tab-light': IconCapTabLight,
  'icons/chevron-right': IconChevronRight,
  'icons/circle-arrow': IconCircleArrow,
  'icons/circle-empty': IconCircleEmpty,
  'icons/circle-error': IconCircleError,
  'icons/circle-success': IconCircleSuccess,
  'icons/close': IconClose,
  'icons/color': IconColor,
  'icons/compress': IconCompress,
  'icons/download': IconDownload,
  'icons/edit-pic': IconEditPic,
  'icons/eraser': IconEraser,
  'icons/exit': IconExit,
  'icons/eye-no-view': IconEyeNoView,
  'icons/eye-view': IconEyeView,
  'icons/face-bad': IconFaceBad,
  'icons/face-happy': IconFaceHappy,
  'icons/face-ok': IconFaceOk,
  'icons/file': IconFile,
  'icons/fullscreen': IconFullscreen,
  'icons/gear': IconGear,
  'icons/google-blue': IconGoogleBlue,
  'icons/google-white': IconGoogleWhite,
  'icons/grid': IconGrid,
  'icons/help': IconHelp,
  'icons/info': IconInfo,
  'icons/keyboard': IconKeyboard,
  'icons/layout': IconLayout,
  'icons/lock': IconLock,
  'icons/mail': IconMail,
  'icons/menu': IconMenu,
  'icons/mesh-world': IconMeshWorld,
  'icons/pip-active': IconPipActive,
  'icons/pip-inactive': IconPipInactive,
  'icons/redo': IconRedo,
  'icons/refresh': IconRefresh,
  'icons/settings': IconSettings,
  'icons/share-paint': IconSharePaint,
  'icons/sign-out-alt': IconSingOutAlt,
  'icons/speakers-off': IconSpeakersOff,
  'icons/speakers-on': IconSpeakersOn,
  'icons/tick': IconTick,
  'icons/times': IconTimes,
  'icons/tool-highlighter': IconToolHighLighter,
  'icons/tool-marker': IconToolMarker,
  'icons/tool-pen': IconToolPen,
  'icons/undo': IconUndo,
  'icons/user': IconUser,
  'icons/users-group': IconUsersGroup,
  'icons/warning': IconWarning,
  'icons/zoom-in': IconZoomIn,
  'icons/zoom-out': IconZoomOut,

  // Logos

  'logos/broadcast-brand': LogoBcastBrand,
  'logos/broadcast-square': LogoBcastSquare,
  'logos/google': LogoGoogle,
}

// ~~~~~~ Types

type SvgKeys = keyof typeof SvgFiles

// ~~~~~~~ Generator

export const LazySVG = (svgKey: SvgKeys) => {
  // ~~~~~~ Component

  return (props: { size: number; [key: string]: any }): JSX.Element => {
    // ~~~~~~ Computed

    const src = SvgFiles[svgKey]

    // ~~~~~~ Render

    return <RemoteSVG src={src} {...props} />
  }
}
