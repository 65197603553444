import { UtilsStore } from '@dn/utils'
import { AppInitState } from '../../../../models/app/model'
import {
  EpicApiIaDescriptionCreateMC,
  EpicApiIaDescriptionCreateMT,
} from '../../../epics/api-ai/description/create/mutators'
import { AiFieldsMR } from '../../../../models/ai/model'
import { AiTimerAT } from '../../../actions/ai-timer/actions'

const initState = AppInitState.aiDescription

type State = typeof initState

const reducer: any = {}

// ####################################################################################################
// ~~~~~~ Create api OK
// ####################################################################################################

reducer[EpicApiIaDescriptionCreateMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof EpicApiIaDescriptionCreateMC.ok>,
): State => {
  return payload.data
}

// ####################################################################################################
// ~~~~~~ Model changed
// ####################################################################################################

reducer[AiFieldsMR.__model__.MT.SET_MODEL] = (
  state: State,
  { payload }: ReturnType<typeof AiFieldsMR.__model__.MC.setModel>,
): State => {
  return payload.description === undefined || payload.description ? state : initState
}

// ####################################################################################################
// ~~~~~~ Timer STOP
// ####################################################################################################

reducer[AiTimerAT.STOP] = (state: State): State => {
  return initState
}

// ~~~~~~

export const aiDescriptionReducer = UtilsStore.dynReducer(initState, reducer)
